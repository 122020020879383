<ul>
    <li *ngIf="alert.details['route']">
        <svg-icon key="ic_line" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
        <span>
            {{alert.details['route']['shortName']}}
            <i nz-tooltip [nzTooltipTitle]="alert.details['route']['longName']" nzTooltipPlacement="right"
                class="fas fa-circle-info ml-1"></i>
        </span>
    </li>
    <li *ngIf="alert.details['bus']">
        <svg-icon key="ic_bus" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
        <div>
            <p *ngFor="let bus of alert.details['bus']">{{bus.id}}</p>
        </div>
    </li>
    <li *ngIf="alert.details['trip']">
        <svg-icon key="ic_clock" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
        <div>
            <p *ngFor="let trip of alert.details['trip']"> {{trip | tripFormat}}</p>
        </div>
    </li>
    <li *ngIf="alert.details['AU']">
        <svg-icon key="ic_agent" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
        <div>
            <p *ngFor="let AU of alert.details['AU']">{{AU}}</p>
        </div>
    </li>
    <li *ngIf="alert.details['trip']">
        <svg-icon key="ic_directions" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
        <div>
            <p *ngFor="let trip of alert.details['trip']"> {{trip.direction | directionFormat}}</p>
        </div>
    </li>
    <li *ngIf="alert.details['servicePlate']">
        <svg-icon key="ic_tag" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
        <div [ngClass]="alert.type === alertTypeEnum.EXCESSIVE_DRIVING ? 'comman-separated-elems' : ''">
            <p *ngFor="let sp of alert.details['servicePlate']">{{sp}}</p>
        </div>
    </li>
</ul>
<div *ngIf="information" class="alert-detail-wrapper">
    <svg-icon key="ic_about" class="inline-flex" color="#464B51" fontSize="24px"></svg-icon>
    <span>{{information}}</span>
</div>

<div *ngIf="alert.escalated" class="alert-detail-wrapper warning-text escalated-info">
    <svg-icon key="ic_angles_up" class="inline-flex" color="#FA6401" fontSize="24px"></svg-icon>
    <span>Alerta escalado</span>
</div>