import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPage } from 'app/pages/main/main.page';
import { AlertDetailsComponent } from './components/alerts-sidebar/components/alert-details/alert-details.component';
import { LastAlertsComponent } from './components/alerts-sidebar/components/last-alerts/last-alerts.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { AuthorizationGuard } from './guards/authorization.guard';
import { DefaultRouteGuard } from './guards/default-route.guard';
import { ForbiddenPage } from './pages/forbidden/forbidden.page';

const routes: Routes = [
    {
        path: '', component: MainPage, canActivate: [AuthenticationGuard], pathMatch: 'prefix', children: [
            { path: '', pathMatch: 'full', canActivate: [DefaultRouteGuard], children: [] },
            {
                path: 'monitoring',
                loadChildren: () => import('./modules/monitoring/monitoring.module').then(m => m.MonitoringModule),
                canActivate: [AuthorizationGuard], data: { roles: ['view-monitoring-page'] }
            },
            {
                path: 'map',
                loadChildren: () => import('./modules/general-map/general-map.module').then(m => m.GeneralMapModule),
                canActivate: [AuthorizationGuard], data: { roles: ['view-general-map'] }
            },
            {
                path: 'history',
                loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
                canActivate: [AuthorizationGuard], data: { roles: ['view-history'] }
            },
            {
                path: 'admin',
                loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
                canActivate: [AuthorizationGuard], data: { roles: ['admin'] }
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthorizationGuard], data: { roles: ['view-dashboard'] }
            },
            {
                path: 'reports',
                loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
                canActivate: [AuthorizationGuard], data: { roles: ['view-reports'] }
            },
            {
                path: 'alerts', outlet: 'alertsSidebar', component: LastAlertsComponent, canActivate: [AuthorizationGuard],
                data: { animation: 'isLeft', roles: ['resolve-alerts', 'resolve-escalated-alerts'] }
            },
            {
                path: 'alerts/resolve', outlet: 'alertsSidebar', component: AlertDetailsComponent, canActivate: [AuthorizationGuard],
                data: { animation: 'isRight', roles: ['resolve-alerts', 'resolve-escalated-alerts'] }
            },
            { path: 'forbidden', component: ForbiddenPage }
        ]
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
