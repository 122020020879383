import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localePtPT from '@angular/common/locales/pt-PT';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterOutline, PlusOutline, SaveOutline, SyncOutline } from '@ant-design/icons-angular/icons';
import { HotToastModule } from '@ngneat/hot-toast';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { createErrorHandler, setUser } from '@sentry/angular-ivy';
import { AlertToastComponent, AlertsSidebarComponent, FooterComponent, NavbarComponent, SubheaderComponent } from 'app/components';
import { MainPage } from 'app/pages/main/main.page';
import { StorageService } from 'app/services/storage/storage.service';
import { pt } from 'date-fns/locale';
import environment from 'environments';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { merge } from 'lodash-es';
import player from 'lottie-web';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NZ_DATE_LOCALE, NZ_I18N, pt_PT } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { LottieModule } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertDetailsComponent } from './components/alerts-sidebar/components/alert-details/alert-details.component';
import { AlertTypeComponent } from './components/alerts-sidebar/components/alert-type/alert-type.component';
import { LastAlertsComponent } from './components/alerts-sidebar/components/last-alerts/last-alerts.component';
import { icons } from './constants';
import { MonitoringService } from './modules/monitoring/services/monitoring.service';
import { SharedModule } from './modules/shared/shared.module';
import { ForbiddenPage } from './pages/forbidden/forbidden.page';
import { ArrivalsService, RoutesControlService } from './services';
import { AuthService } from './services/auth/auth.service';
import { HistoryService } from './services/history/history.service';
import { RealTimeService } from './services/real-time/real-time.service';
import { ReportsService } from './services/reports/reports.service';
import { RoutesService } from './services/routes/routes.service';

registerLocaleData(localePtPT);

const nzConfig: NzConfig = {
    message: { nzTop: 100 }
};

const nzI18n = merge(pt_PT, {
    DatePicker: {
        lang: {
            placeholder: 'Selecionar data',
            yearPlaceholder: 'Selecionar ano',
            monthPlaceholder: 'Selecionar mês',
            weekPlaceholder: 'Selecionar semana',
            rangePlaceholder: ['Data inicial', 'Data final'],
            rangeYearPlaceholder: ['Ano inicial', 'Ano Final'],
            rangeMonthPlaceholder: ['Mês inicial', 'Mês final'],
            rangeWeekPlaceholder: ['Semana inicial', 'Semana final']
        }
    }
});

const initializeApp = (
    keycloakService: KeycloakService,
    translateService: TranslateService,
    monitoringService: MonitoringService,
    arrivalsService: ArrivalsService,
    realTimeService: RealTimeService
) => {
    return () => {
        translateService.setDefaultLang('pt-PT');

        return keycloakService.init({
            config: {
                url: environment.keycloakConfig.url,
                realm: environment.keycloakConfig.realm,
                clientId: environment.keycloakConfig.clientId
            },
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false
            },
            enableBearerInterceptor: environment.keycloakConfig.enableBearerInterceptor,
            loadUserProfileAtStartUp: true
        }).then(() => {
            keycloakService.keycloakEvents$.subscribe(e => {
                if (e.type == KeycloakEventType.OnTokenExpired) {
                    keycloakService.updateToken();
                }
            });

            keycloakService.loadUserProfile().then(userProfile => {
                setUser({
                    id: userProfile.id,
                    username: userProfile.username,
                    email: userProfile.email
                });
            });

            monitoringService.init();
            arrivalsService.init();
            realTimeService.init();
        });
    };
};

@NgModule({
    declarations: [
        AppComponent,
        MainPage,
        ForbiddenPage,
        NavbarComponent,
        SubheaderComponent,
        AlertsSidebarComponent,
        LastAlertsComponent,
        AlertDetailsComponent,
        AlertTypeComponent,
        FooterComponent,
        AlertToastComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        KeycloakAngularModule,
        ToastrModule.forRoot({ maxOpened: 1 }),
        LottieModule.forRoot({ player: () => player }),
        HotToastModule.forRoot(),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useFactory: (http: HttpClient) => new TranslateHttpLoader(http), deps: [HttpClient] },
            defaultLanguage: 'pt-PT',
            isolate: false
        }),
        SharedModule,
        NzNotificationModule,
        NzIconModule.forRoot([SaveOutline, PlusOutline, SyncOutline, FilterOutline]),
        NzBadgeModule,
        NzPaginationModule
    ],
    providers: [
        { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
        { provide: LOCALE_ID, useValue: 'pt-PT' },
        { provide: NZ_I18N, useValue: nzI18n },
        { provide: NZ_DATE_LOCALE, useValue: pt },
        {
            provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [
                KeycloakService, TranslateService, MonitoringService, ArrivalsService, RealTimeService
            ]
        },
        StorageService,
        RoutesService,
        AuthService,
        HistoryService,
        RoutesControlService,
        ReportsService,
        provideSvgIcons(icons.allIcons),
        { provide: NZ_CONFIG, useValue: nzConfig }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
