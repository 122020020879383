import { AfterViewInit, Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { mapConfig } from 'app/constants';
import { AlertModel, AlertTypeEnum } from 'app/models';
import { HistoryService } from 'app/services';
import { getColorStatus } from 'app/utilities/DelayColor';
import { Duration } from 'luxon';
import { take } from 'rxjs';

@Component({
    selector: 'sae-alert-map',
    templateUrl: './alert-map.component.html',
    styleUrls: ['./alert-map.component.scss']
})
export class AlertMapComponent implements AfterViewInit, OnChanges {

    @ViewChild('stopMarker') stopMarker!: MapMarker;
    @ViewChildren('stopMarkers') stopMarkers!: QueryList<MapMarker>;
    @ViewChildren('busMarker') busMarker!: QueryList<MapMarker>;
    @ViewChild('stopInfoWindow') stopInfoWindow!: MapInfoWindow;
    @ViewChildren('busInfoWindow') busInfoWindow!: QueryList<MapInfoWindow>;
    @ViewChildren('stopsInfoWindow') stopsInfoWindow!: QueryList<MapInfoWindow>;
    @ViewChild('legend') legend: ElementRef;

    @Input() alert: AlertModel;

    public alertTypeEnum = AlertTypeEnum;
    public shape: google.maps.LatLng[] = [];
    public realShape: google.maps.LatLng[] = [];
    public tripStops = [];
    public isFullScreen = false;
    public showShape: Record<string, boolean> = {
        estimated: true,
        real: true
    };
    public mapConfig = mapConfig;
    public mapOptions: google.maps.MapOptions = {
        ...mapConfig.mapOptions as google.maps.MapOptions,
        draggable: false,
        clickableIcons: false
    };
    public busStopOptions = {
        ...mapConfig.busStopOptions,
        cursor: 'default',
        clickable: false
    };
    public busOptions: object[] = [];
    public getColorStatus = getColorStatus;
    private map: google.maps.Map;

    constructor(
        private historyService: HistoryService
    ) { }

    ngAfterViewInit(): void {
        const map = document.querySelector('.map') as HTMLElement;
        map.addEventListener('fullscreenchange', () => {
            if (document.fullscreenElement) {
                this.isFullScreen = true;
                this.fitMap();
                this.map.setOptions({
                    ...this.mapOptions,
                    draggable: true,
                    clickableIcons: true
                });
                if (this.stopMarker) {
                    this.stopMarker.marker.setOptions({
                        ...this.busStopOptions,
                        cursor: 'pointer',
                        clickable: true
                    });
                }
                this.stopMarkers.toArray().forEach(sm =>
                    sm.marker.setOptions({
                        ...this.busStopOptions,
                        cursor: 'pointer',
                        clickable: true
                    })
                );
                this.busMarker.toArray().forEach(bm =>
                    bm.marker.setOptions({
                        ...bm.options,
                        cursor: 'pointer',
                        clickable: true
                    })
                );
            } else {
                this.isFullScreen = false;
                this.map.setOptions({
                    ... this.mapOptions,
                    draggable: false,
                    clickableIcons: false
                });
                // Fit to markers
                this.fitMap();

                // Reset markers options
                if (this.stopMarker) {
                    this.stopMarker.marker.setOptions({
                        ...this.busStopOptions
                    });
                }
                this.stopMarkers.toArray().forEach(sm =>
                    sm.marker.setOptions({
                        ...this.busStopOptions
                    })
                );
                this.busMarker.toArray().forEach(bm =>
                    bm.marker.setOptions({
                        ...this.busOptions['options'],
                        cursor: 'default',
                        clickable: false
                    })
                );
                this.busInfoWindow.toArray().forEach(bi =>
                    bi.close()
                );
                this.stopsInfoWindow.toArray().forEach(si =>
                    si.close()
                );
                if (this.stopInfoWindow) {
                    this.stopInfoWindow.close();
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.alert.firstChange || changes.alert.currentValue.id !== changes.alert.previousValue.id) {
            this.initBusOptions();
            if (this.alert.type === AlertTypeEnum.ROUTE_DEVIATION) {
                const startTimeSeconds = Duration.fromISOTime(this.alert.details.trip[0].startTime).as('seconds');
                this.historyService.getTripInfo(this.alert.details.trip[0].id, this.alert.time.toUnixInteger(), startTimeSeconds, this.alert.details.shapeId)
                    .pipe(take(1))
                    .subscribe(res => {
                        this.shape = res['predictedShape'];
                        this.realShape = res['realShape'].filter((_, i) => i % 3 === 0);
                        this.tripStops = res['stops'];
                    });
            } else {
                this.shape = this.realShape = this.tripStops = [];
            }
            if (!changes.alert.firstChange) {
                this.fitMap();
            }
        }
    }

    public mapInitialized(map) {
        this.map = map;
        google.maps.event.addListenerOnce(this.map, 'idle', () => {
            this.fitMap();
        });
        this.initFullscreenControl();
        if (this.alert.type === AlertTypeEnum.ROUTE_DEVIATION) {
            const legendControl = document.querySelector('.sae-legend') as HTMLElement;
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(legendControl);
        }
    }

    private initBusOptions() {
        const mapTypes = [
            AlertTypeEnum.SIGNIFICANT_DELAY,
            AlertTypeEnum.EARLY_DEPARTURE,
            AlertTypeEnum.ROUTE_DEVIATION,
            AlertTypeEnum.PROXIMITY,
            AlertTypeEnum.SAME_SCHEDULE
        ];
        if (mapTypes.includes(this.alert.type)) {
            this.busOptions = this.alert.details.bus.map(bus => ({
                icon: mapConfig.busMarkerIcon(getColorStatus(bus['status'])),
                label: {
                    text: bus['id'],
                    fontWeight: '900',
                    fontFamily: 'Roboto',
                    color: '#434348',
                    fontSize: '12px'
                },
                cursor: 'default',
                clickable: false
            }));
        }
    }

    public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker);
    }

    public toggleShowShape(shape: string) {
        this.showShape[shape] = !this.showShape[shape];
    }

    public fitMap() {
        const bounds = new google.maps.LatLngBounds();
        if (this.alert.details['bus']) {
            this.alert.details.bus.forEach(b => {
                b.position && bounds.extend(b.position);
            });
        }
        if (this.alert.details['stop']) {
            this.alert.details.stop.forEach(s => {
                s.position && bounds.extend(s.position);
            });
        }
        this.map.fitBounds(bounds, { bottom: 20, left: 15, right: 15, top: 30 });
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
            this.map.setCenter(bounds.getCenter());
        });
    }

    private initFullscreenControl() {
        const fullscreenControl = document.querySelector('.fullscreen-control') as HTMLElement;
        fullscreenControl.onclick = () => {
            const elementToSendFullscreen = this.map.getDiv().firstChild as HTMLElement;
            if (document.fullscreenElement === elementToSendFullscreen && document.exitFullscreen) {
                document.exitFullscreen();
            } else {
                if (elementToSendFullscreen.requestFullscreen) {
                    elementToSendFullscreen.requestFullscreen();
                }
            }
        };
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(fullscreenControl);
    }
}
