import { transition, trigger, useAnimation } from '@angular/animations';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { shakeAnimation } from 'app/animations';
import { AlertModel } from 'app/models';
import { AlertsService } from 'app/services';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[alert-toast-component]',
    templateUrl: './alert-toast.component.html',
    styleUrls: ['./alert-toast.component.scss'],
    animations: [
        trigger('shake', [transition(':enter', useAnimation(shakeAnimation))])
    ]
})
export class AlertToastComponent extends Toast implements OnInit, AfterViewInit {

    public errorMessage = '';
    public alertState = '';
    public isLoading = false;
    public showBackdrop = false;

    private playAudioOnShow = false;

    private readonly _toastContainer: HTMLElement;
    private _backdropElem: HTMLElement;

    @Input() alert: AlertModel;
    @Input() onShown: Observable<void>;
    @Input() onHidden: Observable<void>;


    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
        private router: Router,
        private alertsService: AlertsService,
        private renderer: Renderer2
    ) {
        super(toastrService, toastPackage);

        this._toastContainer = document.getElementById('toast-container');
        this._backdropElem = this.renderer.createElement('div');
        this.renderer.addClass(this._backdropElem, 'alert-popup-backdrop');
    }

    ngOnInit(): void {
        if (this.onShown && this.onHidden) {
            this.onShown.pipe(take(1)).subscribe(() => {
                if (this.showBackdrop) this.lockScreen();

                if (document.fullscreenElement) {
                    this.options.disableTimeOut = true;
                    document.onfullscreenchange = _ => {
                        this.options.disableTimeOut = false;
                        this.resetTimeout();
                    };
                }
            });
            this.onHidden.pipe(take(1)).subscribe(() => {
                this.showBackdrop && this.unlockScreen();
            });
        }
    }

    private lockScreen() {
        this._toastContainer.parentNode.prepend(this._backdropElem);
    }

    private unlockScreen() {
        this._toastContainer.parentNode.removeChild(this._backdropElem);
    }

    ngAfterViewInit(): void {
        if (this.playAudioOnShow) {
            this.playAudio();
        }
    }

    playAudio() {
        const audio = new Audio('assets/sound/alert_sound.mp3');
        audio.autoplay = true;
        audio.play()
            .catch(error => {
                if (error.name === 'NotAllowedError') {
                    // eslint-disable-next-line no-console
                    console.warn('Alert sound was not played becauses the user did not interact with the app first.');
                }
            });
    }

    onResolveClick() {
        this.isLoading = true;
        this.alertsService.startResolution(this.alert['id'])
            .pipe(take(1), finalize(() => this.isLoading = false))
            .subscribe({
                next: () => {
                    this.alertsService.sidebarRef
                        .pipe(take(1))
                        .subscribe(alertsSidebarRef => {
                            alertsSidebarRef.open();
                            this.router.navigate(
                                [{ outlets: { alertsSidebar: ['alerts', 'resolve'] } }],
                                { skipLocationChange: true, queryParamsHandling: 'preserve' }
                            );
                            this.remove();
                        });
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status === HttpStatusCode.Forbidden) {
                        this.alertState = 'locked';
                    }
                }
            });
    }

    public static getPopupConfig(appConfig: any, alert: AlertModel) {
        if (!appConfig || !appConfig.priorities || !appConfig.popups) return null;

        const alertPriority = appConfig.priorities.find(elem => elem.alertType === alert.type)?.priority;
        if (!alertPriority) return null;

        const popupConfig = appConfig.popups.find(elem => elem.priority === alertPriority);
        if (!popupConfig || !popupConfig.showPopup) return null;

        return {
            options: {
                tapToDismiss: false,
                timeOut: popupConfig.timeout * 1000,
                extendedTimeOut: popupConfig.timeout * 1000,
                progressBar: true,
                toastClass: `ngx-toastr alert-priority-${alertPriority}`
            },
            playAudioOnShow: popupConfig.playAudioOnShow,
            showBackdrop: popupConfig.showBackdrop
        };
    }
}
