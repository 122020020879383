import { Hydrator } from '../classes';


export class FareZoneModel {

    id: number;
    description: string;
    polygon: google.maps.LatLng[];
    fillColor: string;
    labelColor: string;


    static deserialize(jsonObject: object): FareZoneModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        if (jsonObject['id']) {
            switch (jsonObject['id']) {
                case 1:
                    jsonObject['fillColor'] = '#027CB7';
                    jsonObject['stroke'] = '#027CB7';
                    jsonObject['fill'] = 'rgba(2, 124, 183, 0.2)';
                    break;
                case 2:
                    jsonObject['fillColor'] = '#E20A16';
                    jsonObject['stroke'] = '#E20A16';
                    jsonObject['fill'] = 'rgba(226, 10, 22, 0.2)';
                    break;
            }
        }

        const mapProperties = {
            farePolygon: Hydrator.fromArray(Hydrator.toLatLng)
        };

        const keyTransformer = {
            farePolygon: 'polygon'
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new FareZoneModel());
    }
}
