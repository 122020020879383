<div class="last-alerts">
    <div class="header-wrapper">
        <div>
            <h6 class=" tittle">Últimos alertas</h6>
            <div class="total">Total: {{total}}</div>
        </div>
        <nz-space>
            <button *nzSpaceItem nz-button nzShape="circle" [disabled]="isLoading" (click)="getData()" nz-tooltip
                nzTooltipTitle="Atualizar alertas" nzTooltipPlacement="left" #newAlertsTooltip="nzTooltip"
                [nzTooltipTrigger]="null" (mouseout)="newAlertsMouseOut()">
                <span nz-icon nzType="sync" nzTheme="outline"></span>
            </button>
            <button *nzSpaceItem nz-button nzShape="circle" [disabled]="isLoading" (click)="toggleFilters()">
                <span nz-icon nzType="filter" nzTheme="outline"></span>
            </button>
        </nz-space>
    </div>

    <div *ngIf="!isLoading && total!==0" class="alert-list">
        <div *ngFor="let alert of data" (click)="navigateToAlertDetails(alert)" class="alert-card"
            [ngClass]="{'isLocked': !!alertAnimationState[alert.id]}">
            <div class="d-flex justify-between items-center">
                <div class="font-medium text-gray-7">
                    <i class="far fa-clock mr-2"></i>
                    <span>{{alert.time | dateTimeFormat}}</span>
                </div>
                <div class="d-flex items-center">
                    <span class="error-message mr-1" *ngIf="alertAnimationState[alert.id]" nz-tooltip
                        nzTooltipTitle="Alerta já em resolução" nzTooltipPlacement="left">
                        <i class="fas fa-lock" [@shake]="alertAnimationState[alert.id]"
                            (@shake.done)="alertAnimationState[alert.id] = 'done'"></i>
                    </span>
                    <svg-icon *ngIf="alert.escalated" key="ic_angles_up" class="inline-flex mr-1" color="#464B51"
                        fontSize="24px" width="17px" nz-tooltip nzTooltipTitle="Alerta escalado"
                        nzTooltipPlacement="left"></svg-icon>
                    <div class="counter text-gray-7 uppercase" saeClipboardButton [content]="alert.id">
                        #{{alert.id.substr(alert.id.length - 3)}}
                    </div>
                </div>
            </div>
            <div class="visible">
                <sae-alert-type [alert]="alert"></sae-alert-type>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isLoading">
        <div class="loadingAnimationContainer">
            <div class="sae-ld"></div>
        </div>
    </ng-container>
    <div class="sae-alerts-pagination" *ngIf="total!==0">
        <nz-pagination nzSimple [nzPageIndex]="page" [nzTotal]="total" [nzPageSize]="pageSize"
            (nzPageIndexChange)="pageIndexChange($event)">
        </nz-pagination>
    </div>
</div>
<div [@showAnimation] *ngIf="isFiltering" class="filters-wrapper">
    <div class="filters-modal">
        <div class="filters-modal-header">
            <span class="font-medium">Filtros</span>
            <button class="sae-close-button close" aria-label="Close" (click)="toggleFilters()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="filters-modal-body">
            <form nz-form nzLayout="vertical" [formGroup]="filtersForm" (ngSubmit)="submit()">
                <div class="item-wrapper" (click)="toggleEscalatedAlerts()" *ngIf="allowViewEscalated">
                    <div class="font-medium">Apenas alertas escalados</div>
                    <i class="fa"
                        [ngClass]="filtersForm.get('escalated').value ? 'fa-toggle-on' : ' fa-toggle-off'"></i>
                </div>
                <div>
                    <div class="item-wrapper" (click)="toggleShowTypes()">
                        <div class="font-medium">
                            Tipos de alertas
                            <nz-badge nzStandalone [nzCount]="selectedTypesCount"
                                [nzStyle]="{backgroundColor: '#3f7ab5'}" class="ml-3">
                            </nz-badge>
                        </div>
                        <i class="watch fa-solid" [ngClass]="showTypes ? 'fa-caret-down' : 'fa-caret-right'"></i>
                    </div>
                    <ul [ngClass]="showTypes ? 'show' : 'hide'" class="text-xs">
                        <li *ngFor="let type of AlertTypeList; let i = index" formArrayName="types">
                            <label class="checkbox-label">
                                <input type="checkbox" [value]="type.id" [formControlName]="i">
                                <span class="checkmark"></span>
                                {{type.name}}
                            </label>
                        </li>
                    </ul>
                    <div class="invalid-feedback"
                        *ngIf="filtersForm.controls['types'].errors && filtersForm.controls['types'].errors.requireCheckboxesToBeChecked">
                        * Selecione pelo menos um tipo de alerta</div>
                </div>
                <nz-form-item style="margin-bottom: 0; flex-direction: row;">
                    <nz-form-label>Ordenação</nz-form-label>
                    <nz-form-control>
                        <div nz-row [nzGutter]="8">
                            <div nz-col nzSpan="14">
                                <nz-select formControlName="sortField" [nzOptions]="fields">
                                </nz-select>
                            </div>
                            <div nz-col nzSpan="10">
                                <nz-radio-group formControlName="sortOrder">
                                    <label *ngFor="let o of order" nz-radio-button [nzValue]="o.id">
                                        <i [class]="o.icon"></i>{{o.name}}
                                    </label>
                                </nz-radio-group>
                            </div>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
        <div class="filters-modal-footer">
            <button nz-button (click)="restart()">
                Repor
            </button>
            <button nz-button (click)="submit()" [disabled]="filtersForm.invalid" nzType="primary">
                Aplicar
            </button>
        </div>
    </div>
</div>

<div class="empty-container" *ngIf="total===0 && !isLoading">
    <div>
        <ng-lottie height="100px" [options]="{ path: 'assets/lottie/alert.json', loop: false }">
        </ng-lottie>
        <p>Não existem alertas por resolver!</p>
    </div>
</div>