import { AlertTypeEnum, DirectionType } from 'app/models';
import humanizeDuration from 'humanize-duration';
import { DateTime, Duration } from 'luxon';

export const durationHumanizer = humanizeDuration.humanizer({
    language: 'shortPt',
    languages: {
        shortPt: {
            y: c => 'ano' + (c === 1 ? '' : 's'),
            mo: c => c === 1 ? 'mês' : 'meses',
            w: c => 'semana' + (c === 1 ? '' : 's'),
            d: c => 'dia' + (c === 1 ? '' : 's'),
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms'
        }
    },
    decimal: ',',
    spacer: '',
    delimiter: ' '
});

export function statusFormat(value: { delay: number, status: number }, emptyString = '-'): string {
    if (value.status == undefined || value.delay == undefined) {
        return emptyString;
    }
    const millis = Math.abs(value.delay * 1000);
    const time = durationHumanizer(millis, { round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm'] });
    switch (value.status) {
        case -1:
            return `${time} adiantado`;
        case 1:
            return `${time} atrasado`;
        default:
            return 'A tempo';
    }

}

export function timestampFormat(seconds: number, { format = 'HH:mm', emptyString = '-' } = {}): string {
    if (!seconds) {
        return emptyString;
    }
    return DateTime.fromSeconds(seconds).toFormat(format);
}

export function durationFormat(seconds: number, { format = 'hh:mm', emptyString = '-' } = {}): string {
    if (!seconds) {
        return emptyString;
    }
    return Duration.fromObject({ seconds }).toFormat(format);
}

export function directionFormat(value: DirectionType | string): string {
    const _value = typeof (value) === 'string' ? parseInt(value) : value;
    if (_value === DirectionType.Outward) return 'Ida';
    if (_value === DirectionType.Return) return 'Volta';
    return '-';
}

export function formatCalendar(value): string {
    if (value.description) return `${value.id} (${value.description})`;
    return value.id;
}

export function alertyTypeFormat(value: AlertTypeEnum | string): string {
    const _value = typeof (value) === 'string' ? parseInt(value) : value;
    switch (_value) {
        case AlertTypeEnum.ROUTE_DEVIATION:
            return 'Desvio de rota';
        case AlertTypeEnum.SIGNIFICANT_DELAY:
            return 'Viagem atrasada';
        case AlertTypeEnum.PROXIMITY:
            return 'Proximidade de Serviços';
        case AlertTypeEnum.EARLY_DEPARTURE:
            return 'Saída antecipada';
        case AlertTypeEnum.MISSING_CIRCULATION:
            return 'Falta de circulação';
        case AlertTypeEnum.EXCESSIVE_DRIVING:
            return 'Condução excessiva';
        case AlertTypeEnum.SAME_SCHEDULE:
            return 'Serviços abertos com o mesmo horário';
        default:
            return '-';
    }
}

export function arrayFormat(values: (string | number)[], { emptyString = '-', separator = ', ' } = {}): string {
    if (!values || values.length === 0) {
        return emptyString;
    }
    return values.join(separator);
}

export function tripFormat(trip: object, showTripId = false): string {
    if (!trip) return '-';
    let start = trip['startTime'] || trip['start'];
    let end = trip['endTime'] || trip['end'];
    if (!start || !end) return 'n/a';

    start = start.split(':');
    end = end.split(':');
    let result = `${start[0]}:${start[1]} - ${end[0]}:${end[1]}`;
    if (showTripId) result += ` (${trip['id']})`;
    return result;
}
