
export const getColorStatus = status => {
    let color;
    switch (status) {
        case 0:
            color = 'green';
            break;
        case -1:
            color = 'blue';
            break;
        case 1:
            color = 'red';
            break;
    }
    return color;
};
