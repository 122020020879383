import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Hydrator } from 'app/classes';
import { apiUrlsConstants } from 'app/constants/api-urls.constants';
import { BusPositionModel, DirectionType } from 'app/models';
import { formatFunctions, roundDateTimeToMin } from 'app/utilities';
import { countBy, orderBy, sumBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HistoryService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getRoutes(date: string): Observable<object[]> {
        return this.httpClient.get<object[]>(apiUrlsConstants.history.getRoutes(date))
            .pipe(
                map(res => res.map(elem => ({
                    ...elem,
                    label: `${elem['routeId']} - ${elem['routeName']}`
                })))
            );
    }

    getTrips(date: string, routeId: string, direction: DirectionType): Observable<object[]> {
        return this.httpClient.get<object[]>(apiUrlsConstants.history.getTrips(routeId, date))
            .pipe(
                map(res => res
                    .filter(elem => elem['direction'] === direction)
                    .map(elem => ({
                        ...elem,
                        label: formatFunctions.tripFormat(elem)
                    }))
                )
            );
    }

    getBusIds(date: string): Observable<object[]> {
        return this.httpClient.get<string[]>(apiUrlsConstants.history.getBusIds(date))
            .pipe(
                map(res => res.map(elem => ({ label: elem, value: elem })))
            );
    }

    getDrivers(date: string): Observable<object[]> {
        return this.httpClient.get<string[]>(apiUrlsConstants.history.getDrivers(date))
            .pipe(
                map(res => res.map(elem => ({ label: elem, value: elem })))
            );
    }

    getServicePlates(date: string): Observable<object[]> {
        return this.httpClient.get<string[]>(apiUrlsConstants.history.getPlates(date))
            .pipe(
                map(res => res.sort().map(elem => ({ label: elem, value: elem })))
            );
    }

    getLineHistory(date: string, routeId: number): Observable<object> {
        const url = apiUrlsConstants.history.getLineHistory(date, routeId);
        return this.httpClient.get<object[]>(url)
            .pipe(
                map(res => orderBy(res, 'firstStop.estimatedDepartureTime')),
                map(res => {
                    const busIds = new Set();
                    const aus = new Set();
                    const servicePlates = new Set();
                    let tickets = 0;
                    res.forEach(elem => {
                        (elem['busId'] || []).forEach(busIds.add, busIds);
                        (elem['AU'] || []).forEach(aus.add, aus);
                        (elem['chapa'] || []).forEach(servicePlates.add, servicePlates);
                        tickets += elem['tickets'];
                    });
                    return {
                        data: res,
                        metrics: [
                            { svgIcon: 'ic_bus', label: `${busIds.size} viatura(s)` },
                            { svgIcon: 'ic_agent', label: `${aus.size} motorista(s)` },
                            { svgIcon: 'ic_tag', label: `${servicePlates.size} chapa(s) de serviço` },
                            { svgIcon: 'ic_people', label: `${tickets} bilhete(s)` }
                        ]
                    };
                })
            );
    }

    getScheduleHistory(date: string, tripId: string): Observable<any> {
        return this.httpClient.get<any>(apiUrlsConstants.history.getScheduleHistory(date, tripId))
            .pipe(map(res => {
                if (res.stops.every(elem => elem.arrivalTime == undefined && elem.departureTime == undefined)) {
                    throw Error('Trip data does not exist');
                }

                const estimatedArrivalTime = roundDateTimeToMin(Hydrator.secondsSinceMidnToDateTime(res.stops.at(-1)?.estimatedArrivalTime || 0));
                const estimatedDepartureTime = roundDateTimeToMin(Hydrator.secondsSinceMidnToDateTime(res.stops.at(0)?.estimatedDepartureTime || 0));
                const totalEstimatedTime = estimatedArrivalTime.diff(estimatedDepartureTime).as('minutes');

                const firstDeparture = res.stops.filter(elem => elem.departureTime != undefined).at(0)?.departureTime || 0;
                const lastArrival = res.stops.filter(elem => elem.arrivalTime != undefined).at(-1)?.arrivalTime || 0;

                const arrivalTime = roundDateTimeToMin(Hydrator.timestampToDateTime(lastArrival));
                const departureTime = roundDateTimeToMin(Hydrator.timestampToDateTime(firstDeparture));
                const totalTime = (arrivalTime && departureTime) ? arrivalTime.diff(departureTime).as('minutes') : 0;

                const distance = res.distance / 1000;

                const alertsCountByType = Object.entries(countBy(res.alerts, 'Type'))
                    .map(([type, count]) => ({
                        label: formatFunctions.alertyTypeFormat(type),
                        value: count
                    }));
                const alertsTotalCount = sumBy(alertsCountByType, 'value');

                const tickets = res.stops.reduce((acc, curr) => acc + curr.tickets, 0);

                const busIds = Array.from(res.busId || []).join(', ');
                const aus = Array.from(res.au || []).join(', ');
                const servicePlates = Array.from(res.chapa || []).join(', ');

                return {
                    stops: res['stops'],
                    shapeId: res['shapeId'],
                    metrics: [
                        { svgIcon: 'ic_time', label: `${totalEstimatedTime} minuto(s)` },
                        { svgIcon: 'ic_clock', label: totalTime < 0 ? '-' : `${totalTime} minuto(s)` },
                        { svgIcon: 'ic_people', label: `${tickets} bilhete(s)` },
                        { svgIcon: 'ic_road', label: `${distance.toFixed(2)} km` },
                        { svgIcon: 'ic_alert', label: `${alertsTotalCount} alerta(s)`, children: alertsCountByType }
                    ],
                    info: [
                        { svgIcon: 'ic_bus', label: 'Viatura', value: busIds },
                        { svgIcon: 'ic_agent', label: 'Agente Único', value: aus },
                        { svgIcon: 'ic_tag', label: 'Chapa de Serviço', value: servicePlates }
                    ]
                };
            }));
    }

    getVehicleHistory(date: string, busId: number): Observable<object> {
        return this.httpClient.get<object[]>(apiUrlsConstants.history.getVehicleHistory(date, busId))
            .pipe(
                map(res => {
                    const routeIds = new Set();
                    const aus = new Set();
                    const servicePlates = new Set();
                    let tickets = 0;
                    let distance = 0;
                    res.forEach(elem => {
                        routeIds.add(elem['routeId']);
                        (elem['AU'] || []).forEach(aus.add, aus);
                        (elem['chapa'] || []).forEach(servicePlates.add, servicePlates);
                        tickets += elem['tickets'];
                        distance += elem['distance'];
                    });
                    distance = distance / 1000;
                    return {
                        data: res,
                        metrics: [
                            { svgIcon: 'ic_line', label: `${routeIds.size} linha(s)` },
                            { svgIcon: 'ic_agent', label: `${aus.size} motorista(s)` },
                            { svgIcon: 'ic_tag', label: `${servicePlates.size} chapa(s) de serviço` },
                            { svgIcon: 'ic_people', label: `${tickets} bilhete(s)` },
                            { svgIcon: 'ic_road', label: `${distance.toFixed(2)} quilómetro(s)` }
                        ]
                    };
                })
            );
    }

    getAgencyParkGeofences(params): Observable<object> {
        return this.httpClient.get<object[]>(apiUrlsConstants.history.getAgencyParkGeofences, { params });
    }

    getDriverHistory(date: string, driver: number): Observable<object> {
        return this.httpClient.get<object[]>(apiUrlsConstants.history.getDriverHistory(date, driver))
            .pipe(
                map(res => {
                    const routeIds = new Set();
                    const busIds = new Set();
                    const servicePlates = new Set();
                    let tickets = 0;
                    res.forEach(elem => {
                        routeIds.add(elem['routeId']);
                        (elem['busId'] || []).forEach(busIds.add, busIds);
                        (elem['chapa'] || []).forEach(servicePlates.add, servicePlates);
                        tickets += elem['tickets'];
                    });
                    return {
                        data: res,
                        metrics: [
                            { svgIcon: 'ic_line', label: `${routeIds.size} linha(s)` },
                            { svgIcon: 'ic_bus', label: `${busIds.size} viatura(s)` },
                            { svgIcon: 'ic_tag', label: `${servicePlates.size} chapa(s) de serviço` },
                            { svgIcon: 'ic_people', label: `${tickets} bilhete(s)` }
                        ]
                    };
                })
            );
    }

    getServicePlateHistory(date: string, servicePlate: number): Observable<object> {
        return this.httpClient.get<object[]>(apiUrlsConstants.history.getServicePlateHistory(date, servicePlate))
            .pipe(
                map(res => {
                    const routeIds = new Set();
                    const busIds = new Set();
                    const aus = new Set();
                    let tickets = 0;
                    res.forEach(elem => {
                        routeIds.add(elem['routeId']);
                        (elem['busId'] || []).forEach(busIds.add, busIds);
                        (elem['AU'] || []).forEach(aus.add, aus);
                        tickets += elem['tickets'];
                    });
                    return {
                        data: res,
                        metrics: [
                            { svgIcon: 'ic_line', label: `${routeIds.size} linha(s)` },
                            { svgIcon: 'ic_bus', label: `${busIds.size} viatura(s)` },
                            { svgIcon: 'ic_agent', label: `${aus.size} motorista(s)` },
                            { svgIcon: 'ic_people', label: `${tickets} bilhete(s)` }
                        ]
                    };
                })
            );
    }

    getBusLocation(time: number, tripId: string): Observable<BusPositionModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.history.getBusLocation(time, tripId))
            .pipe(map(Hydrator.fromArray(BusPositionModel.deserialize)));
    }

    getShape(shapeId: string, date: string): Observable<any> {
        return this.httpClient
            .get(apiUrlsConstants.history.getShape(shapeId, date))
            .pipe(map(Hydrator.fromArray(Hydrator.toLatLng)));
    }

    getTripInfo(tripId: string, time: number, startTime: number, shapeId: string): Observable<any> {
        return this.httpClient
            .get(apiUrlsConstants.history.getTripInfo(tripId, time, startTime, shapeId))
            .pipe(map(obj => {
                const realShape = obj['realShape'] ? obj['realShape'].map(Hydrator.toLatLng) : [];
                const predictedShape = obj['predictedShape'].map(Hydrator.toLatLng);
                const stops = obj['stops'].map(s => {
                    s['id'] = s['stop'];
                    s['name'] = s['stopName'];
                    s['time'] = Hydrator.secondsToDuration(s['commit'] ? s['estimatedDepartureTime'] : s['estimatedArrivalTime']);
                    s['position'] = Hydrator.toLatLng(s);
                    return s;
                });
                return { realShape, predictedShape, stops };
            }));
    }

    getAgencyPark() {
        return this.httpClient.get(apiUrlsConstants.history.agencyPark);
    }

    updateAgencyPark(body) {
        return this.httpClient.put(apiUrlsConstants.history.agencyPark, body);
    }
}
