<div class="fixed-top">
    <nav class="sae-navbar navbar">
        <div class="container-xl navbar-container">
            <div class="sae-navbar-menu">
                <a [routerLink]="['/']" class="navbar-logo">
                    <img src="assets/img/mobibus/navbar-logo.png">
                </a>
                <ul>
                    <li *ngFor="let link of allowedLinks" class="sae-navbar-item">
                        <a [routerLink]="[link.link]">
                            <i class="fa fa-{{link.icon}}" *ngIf="link.icon"></i>
                            <span>{{link.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="sae-navbar-menu">
                <ul>
                    <li *ngIf="canResolveAlerts" class="sae-navbar-item rounded">
                        <nz-badge [nzCount]="notViewedAlertsCount" nzOverflowCount="99" nzSize="small">
                            <a (click)="alertsSidebarRef.open()" style="width: 40px; height: 40px;"
                                [routerLink]="[{ outlets: { alertsSidebar: ['alerts'] } }]"
                                skipLocationChange queryParamsHandling="preserve">
                                <i class="far fa-bell mr-0"></i>
                            </a>
                        </nz-badge>
                    </li>
                    <li class="sae-navbar-item rounded">
                        <a routerLink="/monitoring/fleet-status">
                            <i class="fa-solid fa-heart-pulse mr-0"></i>
                        </a>
                    </li>
                    <li #userDropdown class="position-relative sae-navbar-item rounded">
                        <a id="user-item" [routerLink]="[]" (click)="showUserDropdown = !showUserDropdown"
                            [ngClass]="{active: showUserDropdown}">
                            <i class="far fa-user mr-0"></i>
                        </a>

                        <div class="user-info-dropdown" [ngClass]="{closed: !showUserDropdown}">
                            <div [ngClass]="{'d-none': !showUserDropdown}">
                                <div class="light-background h-auto">
                                    <div class="dropdown-container pb-0">
                                        <span class="user-info-title">
                                            <b>{{userInfoTitle}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="light-background position-absolute rounded-0"></div>

                                <div class="dropdown-container border-bottom mb-3">
                                    <div class="avatar mb-2">{{userInitials}}</div>
                                    <span>{{userProfile.username}}</span>
                                    <span>{{userProfile.email}}</span>
                                </div>

                                <div class="dropdown-container pl-0 pr-0 pt-0">
                                    <a class="dropdown-item" routerLink="/admin"
                                        (click)="showUserDropdown = !showUserDropdown"
                                        *ngIf="userRoles.includes('admin')">
                                        <i class="fas fa-user-shield mr-2"></i>
                                        Painel de Administração
                                    </a>
                                    <a class="dropdown-item" [routerLink]="[]" (click)="logout()">
                                        <i class="fas fa-right-from-bracket mr-2"></i>
                                        Terminar sessão
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <sae-subheader *ngIf="!route.url.startsWith('/dashboard')"></sae-subheader>
</div>