import { Hydrator } from '../classes';

export class AlertTag {

    id: number;
    name: string;

    static deserialize(jsonObject: object): AlertTag {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
        };

        const keyTransformer = {
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new AlertTag());
    }

}
