import { HttpClient, HttpParams } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Hydrator } from 'app/classes';
import { apiUrlsConstants } from 'app/constants/api-urls.constants';
import { ArrivalModel, BusStopArrivalModel, TripStatusModel } from 'app/models';
import { Observable, Subject, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BusInfo } from '../../components/subheader/subheader.types';
import { BusState, fromString as busStateFromString } from '../../models/bus-state.enum';

@Injectable({
    providedIn: 'root'
})
export class ArrivalsService {

    private scheduleArrivalObservable = new Subject<ArrivalModel>();
    private _busesStates = new Subject<Map<BusState, BusInfo[]>>();
    private destroyRef = inject(DestroyRef);

    constructor(
        private httpClient: HttpClient
    ) { }

    init() {
        timer(500, 3000)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(() => this._busesStates.observers.length > 0),
                switchMap(() => this.getBusesStates())
            )
            .subscribe(value => this._busesStates.next(value));
    }

    get busesStates(): Observable<Map<BusState, BusInfo[]>> {
        return this._busesStates.asObservable();
    }

    getTripArrivals(tripId: string): Observable<any[]> {
        return this.httpClient.get<any[]>(apiUrlsConstants.arrivals.getTripArrivals(tripId));
    }

    getRouteTripsStatus(routeId: number): Observable<TripStatusModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.arrivals.getRouteTripsStatus(routeId))
            .pipe(map(Hydrator.fromArray(TripStatusModel.deserialize)));
    }

    getScheduleArrivalObservable(tripId: string): Observable<ArrivalModel> {
        return this.scheduleArrivalObservable.asObservable()
            .pipe(filter(arrival => arrival.tripId === tripId));
    }

    emitScheduleArrival(arrival: ArrivalModel) {
        this.scheduleArrivalObservable.next(arrival);
    }

    getBusesStates(): Observable<Map<BusState, BusInfo[]>> {
        return this.httpClient
            .get(apiUrlsConstants.arrivals.getGlobalStatus())
            .pipe(map(obj => {
                const busStates = new Map();
                Object.keys(obj).forEach(key => {
                    busStates.set(busStateFromString(key), obj[key]);
                });
                return busStates;
            }));
    }

    getBusStopArrivals(stopId: number): Observable<BusStopArrivalModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.arrivals.getBusStopArrivals(stopId))
            .pipe(map(Hydrator.fromArray(BusStopArrivalModel.deserialize)));
    }

    getNextArrivalForRoutes(stopId: number, routeIds: number[]): Observable<BusStopArrivalModel[]> {
        const params = new HttpParams().append('routes', routeIds.join(', '));

        return this.httpClient
            .get(apiUrlsConstants.arrivals.getNextArrivalForRoutes(stopId), { params })
            .pipe(map(Hydrator.fromArray(BusStopArrivalModel.deserialize)));
    }

    getTripsStatus(trips: string[]): Observable<any> {
        const params = new HttpParams({
            fromObject: { trips_id: trips }
        });

        return this.httpClient
            .get(apiUrlsConstants.arrivals.getTripsStatus(), { params });
    }

    getTemporalDistance(busID: string, routeId: number, direction: number) {
        const params = new HttpParams({
            fromObject: {
                routeId,
                direction
            }
        });

        return this.httpClient
            .get(apiUrlsConstants.arrivals.getBusTemporalDistance(busID), { params });

    }

}
