import { animate, group, query, style, transition, trigger } from '@angular/animations';

enum Direction {
    LEFT,
    RIGHT
}

function slideTo(direction: Direction) {
    let enterInitialX;
    let leaveFinalX;
    if (direction === Direction.RIGHT) {
        enterInitialX = 100;
        leaveFinalX = -100;
    } else {
        enterInitialX = -100;
        leaveFinalX = 100;
    }
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                left: '0',
                right: '0'
            })
        ], { optional: true }),
        group([
            query(':enter', [
                style({ transform: `translateX(${enterInitialX}%)` }),
                animate('0.4s ease-in-out', style({ transform: 'translateX(0%)' }))
            ], { optional: true }),
            query(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate('0.4s ease-in-out', style({ transform: `translateX(${leaveFinalX}%)` }))
            ], { optional: true })
        ])
    ];
}

export const sliderAnimation = trigger('routeAnimations', [
    transition('isLeft => isRight', slideTo(Direction.RIGHT)),
    transition('isRight => isLeft', slideTo(Direction.LEFT))
]);