export enum BusState {
    ONSERVICE,
    ONTIME,
    LATE,
    INADVANCE,
    EMPTY,
}

export function fromString(type: string): BusState {
    switch (type) {
        case 'on_time':
            return BusState.ONTIME;
        case 'on_delay':
            return BusState.LATE;
        case 'in_advance':
            return BusState.INADVANCE;
    }
}
