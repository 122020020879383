import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init as initSentry } from '@sentry/angular-ivy';
import 'chartjs-adapter-luxon';
import { Settings } from 'luxon';
import { AppModule } from './app/app.module';
import environment from './environments';

initSentry({
    enabled: environment.ENV !== 'local',
    dsn: environment.sentry.dns,
    release: environment.version,
    // FIXME: Due to CORS error
    // autoSessionTracking: false,
    tracesSampleRate: 0.5,
    environment: environment.ENV
});

if (environment.ENV !== 'local') enableProdMode();

Settings.defaultLocale = 'pt';

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
