import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';


@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(datetime: DateTime, format = 'dd/MM/yyyy HH:mm', emptyString = '-'): string {
        if (!datetime) {
            return emptyString;
        }
        return datetime.toFormat(format);
    }

}
