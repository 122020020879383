import { Hydrator } from '../classes';

export class RouteModel {

    id: number;
    shortName: string;
    longName: string;
    desc: string;
    agencyId: string;


    static deserialize(jsonObject: object): RouteModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        if (jsonObject['shortName'] && jsonObject['longName']) {
            jsonObject['desc'] = `${jsonObject['shortName']} - ${jsonObject['longName']}`;
        }

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, {}), new RouteModel());
    }

    static compare(routeA: RouteModel, routeB: RouteModel): boolean {
        return routeA.id === routeB.id;
    }

    compareTo = (route: RouteModel): boolean => {
        return this.id === route.id;
    };

}
