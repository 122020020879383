import { transition, trigger, useAnimation } from '@angular/animations';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeAnimation, shakeAnimation } from 'app/animations';
import { AlertModel } from 'app/models';
import { AlertsService } from 'app/services/alerts/alerts.service';
import { AlertTypeList } from 'app/utilities';
import { KeycloakService } from 'keycloak-angular';
import { isEmpty } from 'lodash-es';
import { NzTooltipBaseDirective } from 'ng-zorro-antd/tooltip';
import { Subscription, take } from 'rxjs';

@Component({
    selector: 'sae-last-alerts',
    templateUrl: './last-alerts.component.html',
    styleUrls: ['./last-alerts.component.scss'],
    animations: [
        trigger('shake', [transition('* => started', useAnimation(shakeAnimation))]),
        trigger('showAnimation', [...fadeAnimation()])
    ]
})
export class LastAlertsComponent implements OnInit {

    @ViewChild('newAlertsTooltip') newAlertsTooltip: NzTooltipBaseDirective;

    readonly pageSize = 10;
    page = 1;

    public alertAnimationState = {};
    public data: AlertModel[] = [];
    public total = 0;
    public isLoading = true;
    private alertsRequest: Subscription;
    public isFiltering = false;
    public allowViewEscalated = false;
    public showOnlyEscalated = false;
    public showTypes = false;
    public AlertTypeList = AlertTypeList;
    public types = [];
    public selectedTypesCount = 0;
    public filtersForm: UntypedFormGroup;
    public fields = [
        { value: 'time', label: 'Data do alerta' },
        // { value: 'priority', label: 'Prioridade' }, // Timeout
        { value: 'type', label: 'Tipo de alerta' }
    ];
    public order = [
        { id: 1, icon: 'fas fa-sort-amount-up' },
        { id: -1, icon: 'fas fa-sort-amount-down-alt' }
    ];
    public selectedSortField;
    public selectedSortOrder;

    constructor(
        private router: Router,
        private alertsService: AlertsService,
        private keycloak: KeycloakService,
        private formBuilder: UntypedFormBuilder
    ) {
        if (this.router.getCurrentNavigation().extras.state) {
            const state = this.router.getCurrentNavigation().extras.state;
            this.page = state.page;
            this.pageSize = state.perPage;
        }
        this.allowViewEscalated = this.keycloak.isUserInRole('view-escalated-alerts');
        // Check filters stored on browser
        const filters = this.alertsService.filters;

        if (isEmpty(filters)) {
            this.showOnlyEscalated = this.keycloak.isUserInRole('resolve-escalated-alerts');
            this.types = this.AlertTypeList.map(_ => true);
            this.selectedSortField = this.fields[0].value;
            this.selectedSortOrder = this.order[1].id;
        } else {
            this.showOnlyEscalated = filters['escalated'];
            this.types = filters['types'];
            this.selectedSortField = filters['sortField'];
            this.selectedSortOrder = filters['sortOrder'];
        }
        // Create filters form
        this.filtersForm = this.formBuilder.group({
            escalated: this.showOnlyEscalated,
            types: this.formBuilder.array(this.types, this.validateTypes()),
            sortField: new UntypedFormControl(this.selectedSortField),
            sortOrder: new UntypedFormControl(this.selectedSortOrder)
        });
        this.selectedTypesCount = this.filtersForm.get('types').value.filter(t => t === true).length;
        this.filtersForm.get('types').valueChanges
            .pipe(takeUntilDestroyed())
            .subscribe(v => this.selectedTypesCount = v.filter(t => t === true).length);

        this.alertsService.alertEvents
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.newAlertsTooltip?.show());
    }

    ngOnInit() {
        this.getData();
    }

    navigateToAlertDetails(alert: AlertModel) {
        this.alertsService.startResolution(alert.id)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.router.navigate(
                        [{ outlets: { alertsSidebar: ['alerts', 'resolve'] } }],
                        { state: { page: this.page, perPage: this.pageSize }, skipLocationChange: true, queryParamsHandling: 'preserve' }
                    );
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status === HttpStatusCode.Forbidden) {
                        this.alertAnimationState = {
                            ...this.alertAnimationState,
                            [alert.id]: 'started'
                        };
                    }
                }
            });
    }

    getData() {
        this.isLoading = true;
        const skip = (this.page - 1) * this.pageSize;
        if (this.alertsRequest) {
            this.alertsRequest.unsubscribe();
        }
        this.newAlertsTooltip?.hide();
        // Check types
        const types = [];
        if (this.types.some(t => !t)) {
            this.types.forEach((t, i) => {
                if (this.AlertTypeList[i]) t && types.push(this.AlertTypeList[i].id);
            });
        }
        this.alertsRequest = this.alertsService.getAlerts(skip, this.pageSize, this.showOnlyEscalated, types, this.selectedSortField, this.selectedSortOrder)
            .pipe(take(1))
            .subscribe(alerts => {
                this.data = alerts.data;
                this.total = alerts.total;
                this.isLoading = false;
                this.alertAnimationState = {};
            });
    }

    private validateTypes(): ValidatorFn {
        return function validate(formGroup: UntypedFormGroup) {
            let checked = 0;
            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];
                if (control.value === true) checked++;
            });
            if (checked < 1) {
                return { requireCheckboxesToBeChecked: true };
            }
            return null;
        };
    }

    toggleFilters() {
        this.isFiltering = !this.isFiltering;
        this.showTypes = false;
        this.restart();
    }

    toggleEscalatedAlerts() {
        const prevEscalated = this.filtersForm.get('escalated').value;
        this.filtersForm.patchValue({ escalated: !prevEscalated });
    }

    toggleShowTypes() {
        this.showTypes = !this.showTypes;
    }

    restart() {
        this.filtersForm.patchValue({
            escalated: this.showOnlyEscalated,
            types: this.types,
            sortField: this.selectedSortField,
            sortOrder: this.selectedSortOrder
        });
    }

    submit() {
        this.isFiltering = false;
        this.page = 1;
        this.showTypes = false;
        const values = this.filtersForm.value;
        this.types = values.types;
        this.showOnlyEscalated = values.escalated;
        this.selectedSortField = values.sortField;
        this.selectedSortOrder = values.sortOrder;
        this.alertsService.updateStorage(values);
        this.getData();
    }

    fieldsComparator(p1: any, p2: any) {
        return p1.id && p2.id && p1.id === p2.id;
    }

    newAlertsMouseOut() {
        this.newAlertsTooltip?.hide();
    }

    pageIndexChange(page) {
        this.page = page;
        this.getData();
    }
}
