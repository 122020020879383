import { animate, style, transition } from '@angular/animations';

export function fadeAnimation() {
    return [
        transition(':enter', [
            style({ opacity: 0, transform: 'translateY(10%)' }),
            animate('150ms ease-in-out', style({ opacity: 1, transform: 'translateY(0%)' }))
        ]),
        transition(':leave', [
            style({ opacity: 1, transform: 'translateY(0%)' }),
            animate('150ms ease-in-out', style({ opacity: 0, transform: 'translateY(10%)' }))
        ])
    ];
}
