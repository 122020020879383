import environment from 'environments';

const API_BASE_URL = `${environment.API_URL}` || 'http://127.0.0.1';

export const apiUrlsConstants = {
    routes: {
        getRoute: (routeId: number): string => (
            `${API_BASE_URL}/routes/routes/${routeId}`
        ),
        getRoutes: (): string => (
            `${API_BASE_URL}/routes/routes`
        ),
        getRouteSpines: (routeId: string): string => (
            `${API_BASE_URL}/routes/routes/${routeId}/spines`
        ),
        getShapes: (): string => (
            `${API_BASE_URL}/routes/shapes/groupByIds`
        ),
        getFareZones: (): string => (
            `${API_BASE_URL}/routes/fares/zones`
        ),
        getAreas: (): string => (
            `${API_BASE_URL}/routes/areas`
        ),
        getStops: (): string => (
            `${API_BASE_URL}/routes/stops`
        ),
        getBusStopRoutes: (stopId: number): string => (
            `${API_BASE_URL}/routes/stops/${stopId}/routes`
        ),
        getCalendars: (): string => (
            `${API_BASE_URL}/routes/calendars`
        ),
        getTrips: () => (
            `${API_BASE_URL}/routes/trips`
        ),
        getTripwithRoute: (tripId: string) => (
            `${API_BASE_URL}/routes/trips/${tripId}?filter={"include":["route"]}`
        ),
        getStopsSequence: (tripId: string) => (
            `${API_BASE_URL}/routes/trips/${tripId}/stopTimes?filter={"include":["stop"]}`
        ),
        getRoutesCount: (): string => (
            `${API_BASE_URL}/routes/routes/count`
        ),
        getStopCount: (): string => (
            `${API_BASE_URL}/routes/stops/count`
        ),
        holidays: `${API_BASE_URL}/routes/holidays/service`,
        serviceId: `${API_BASE_URL}/routes/holidays/serviceId`
    },

    realTime: {
        getBusPositionsByRoute: (routeId: number): string => (
            `${API_BASE_URL}/busLocation/${routeId}`
        ),
        getBusPositions: (): string => (
            `${API_BASE_URL}/busLocation/getAll`
        ),
        getBusPositionsByID: (routeId: number, busId: string): string => (
            `${API_BASE_URL}/busLocation/getBus/${routeId}/${busId}`
        )
    },

    arrivals: {
        getTripArrivals: (tripId: string): string => (
            `${API_BASE_URL}/arrivals/arrivals/${tripId}`
        ),
        getRouteTripsStatus: (routeId: number): string => (
            `${API_BASE_URL}/arrivals/arrivals/${routeId}/status`
        ),
        getBusStopArrivals: (busStopId: number): string => (
            `${API_BASE_URL}/arrivals/arrivals/v2/stop/${busStopId}?delay=0`
        ),
        getNextArrivalForRoutes: (busStopId: number): string => (
            `${API_BASE_URL}/arrivals/arrivals/stop/${busStopId}/routes`
        ),
        getGlobalStatus: () => (
            `${API_BASE_URL}/arrivals/arrivals/global/status`
        ),
        getTripsStatus: () => (
            `${API_BASE_URL}/arrivals/arrivals/trips/status`
        ),
        getBusTemporalDistance: (busId: string) => (
            `${API_BASE_URL}/arrivals/service/distance/${busId}`
        )
    },

    routesControl: {
        getBusPhysicalDistance: (busId: string) => (
            `${API_BASE_URL}/route-control/v1/distance/${busId}`
        )
    },

    events: {
        subscribe: () => `${API_BASE_URL}/events/subscribe`
    },

    alerts: {
        getAlerts: () => `${API_BASE_URL}/alerts/v1/alerts`,
        escalate: alertId => `${API_BASE_URL}/alerts/v1/alerts/${alertId}/escalate`,
        startResolution: alertId => `${API_BASE_URL}/alerts/v1/alerts/${alertId}/start_resolution`,
        cancel: alertId => `${API_BASE_URL}/alerts/v1/alerts/${alertId}/cancel`,
        resolve: alertId => `${API_BASE_URL}/alerts/v1/alerts/${alertId}/resolve`,
        getTags: () => `${API_BASE_URL}/alerts/v1/alerts/tags`,
        getAlertsHistory: () => `${API_BASE_URL}/alerts/v1/alerts/history`,
        getNotViewed: () => `${API_BASE_URL}/alerts/v1/alerts/notViewed`,
        settings: () => `${API_BASE_URL}/alerts/v1/alerts/settings`
    },

    history: {
        getRoutes: (date: string): string => (
            `${API_BASE_URL}/history/v1/routes?date=${date}`
        ),
        getTrips: (routeId: string, date: string): string => (
            `${API_BASE_URL}/history/v1/trips/${routeId}?date=${date}`
        ),
        getBusIds: (date: string): string => (
            `${API_BASE_URL}/history/v1/bus?date=${date}`
        ),
        getDrivers: (date: string): string => (
            `${API_BASE_URL}/history/v1/au?date=${date}`
        ),
        getPlates: (date: string): string => (
            `${API_BASE_URL}/history/v1/chapa?date=${date}`
        ),
        getLineHistory: (date: string, lineId: number): string => (
            `${API_BASE_URL}/history/v1/route/${lineId}?date=${date}`
        ),
        getScheduleHistory: (date: string, tripId: string): string => (
            `${API_BASE_URL}/history/v1/stopTimes/${tripId}?date=${date}`
        ),
        getVehicleHistory: (date: string, busId: number): string => (
            `${API_BASE_URL}/history/v1/bus/${busId}?date=${date}`
        ),
        getAgencyParkGeofences: (
            `${API_BASE_URL}/history/v1/park/geofences`
        ),
        getDriverHistory: (date: string, driver: number): string => (
            `${API_BASE_URL}/history/v1/au/${driver}?date=${date}`
        ),
        getServicePlateHistory: (date: string, servicePlate: number): string => (
            `${API_BASE_URL}/history/v1/chapa/${servicePlate}?date=${date}`
        ),
        getBusLocation: (time: number, tripId: string): string => (
            `${API_BASE_URL}/history/v1/mapview/${tripId}?time=${time}`
        ),
        getShape: (shapeId: string, date: string): string => (
            `${API_BASE_URL}/history/v1/shapes/${shapeId}?date=${date}`
        ),
        getTripInfo: (tripId: string, time: number, startTime: number, shapeId: string): string => (
            `${API_BASE_URL}/history/v1/allTripInfo/${tripId}?time=${time}&startTime=${startTime}&shapeId=${shapeId}`
        ),
        agencyPark: `${API_BASE_URL}/history/v1/park`
    },

    metrics: {
        goals: `${API_BASE_URL}/metrics/v1/goals`,
        getMetrics: (): string => `${API_BASE_URL}/metrics/v1/metrics`
    },

    reports: {
        getReportsList: (): string => `${API_BASE_URL}/v1/reports`,
        getReport: (reportId: number): string => `${API_BASE_URL}/v1/reports/${reportId}`,
        getReportDetails: (reportId: number): string => `${API_BASE_URL}/v1/reports/${reportId}/details`
    },

    gtfsUpdater: {
        update: `${API_BASE_URL}/gtfs-updater/v1/update`,
        nextUpdate: `${API_BASE_URL}/gtfs-updater/v1/update/next`,
        updateReport: `${API_BASE_URL}/gtfs-updater/v1/update/report`
    }
};
