import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlsConstants } from 'app/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getReports(): Observable<any> {
        return this.httpClient.get(apiUrlsConstants.reports.getReportsList());
    }

    getReportDetails(reportId: number): Observable<object> {
        return this.httpClient.get(apiUrlsConstants.reports.getReportDetails(reportId));
    }

    downloadReport(reportId: number, filters: Record<string, string>): Observable<object> {
        const params = new HttpParams({ fromObject: filters });
        return this.httpClient.get(apiUrlsConstants.reports.getReport(reportId), { params, observe: 'response', responseType: 'blob' })
            .pipe(map(res => {
                const blob = new Blob([res.body], { type: res.body.type });
                const contentDisposition = res.headers.get('Content-Disposition');
                const filename = contentDisposition?.split('filename=')[1].replace(/['"]+/g, '');
                const href = URL.createObjectURL(blob);
                return { blob, filename, href };
            }));

    }
}
