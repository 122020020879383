import { Pipe, PipeTransform } from '@angular/core';
import { formatFunctions } from 'app/utilities';


@Pipe({
    name: 'directionFormat'
})
export class DirectionFormatPipe implements PipeTransform {

    transform = formatFunctions.directionFormat.bind(this);

}
