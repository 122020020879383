<div class="sae-section -small alert-section" *ngIf="alert">
    <div class="d-flex">
        <h6 style="flex: 70%">{{alert.type | alertTypeFormat}}</h6>
        <h6 style="flex: 30%" class="uppercase justify-end" saeClipboardButton [content]="alert.id">
            #{{alert.id.substr(alert.id.length - 3)}}
        </h6>
    </div>

    <div>
        <div class="alert-wrapper">
            <div class="time">
                <i class="far fa-clock mr-1"></i>{{alert.time | dateTimeFormat}}
            </div>
            <i class="watch fa-solid fa-eye"
                *ngIf="userRoles.includes('planning-operator') || userRoles.includes('planning-operator')"
                (click)="toggleShowViewers()"></i>
            <div class="watch-dropdown" [ngClass]="showViewers ? 'show' : 'hidden'">
                <ul *ngIf="alert.viewers">
                    <li *ngFor="let viewer of alert.viewers">
                        <i class="fas fa-user"></i>
                        <div>
                            <p>{{ viewer.user['displayName'] }}</p>
                            <p class="viewer-time">{{ viewer['time'] | dateTimeFormat }}</p>
                        </div>
                    </li>
                </ul>
                <div *ngIf="!alert.viewers" class="noViewers">
                    Alerta sem visualizações!
                </div>
            </div>
        </div>
        <div class="map"
            *ngIf="![alertTypeEnum.MISSING_CIRCULATION, alertTypeEnum.EXCESSIVE_DRIVING].includes(alert.type)">
            <sae-alert-map [alert]="alert"></sae-alert-map>
        </div>

        <div class="subheader">
            Detalhe do alerta
        </div>
        <sae-alert-info [alert]="alert"></sae-alert-info>

        <form nz-form nzLayout="vertical" [formGroup]="formGroup">
            <nz-form-item>
                <nz-form-label>Operador</nz-form-label>
                <nz-form-control>
                    <input nz-input formControlName="operator" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label>Tipo</nz-form-label>
                <nz-form-control>
                    <nz-select formControlName="tag" nzPlaceHolder="Selecione um tipo">
                        <nz-option *ngFor="let o of tags" [nzValue]="o.id" [nzLabel]="o.name"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label>Motivo</nz-form-label>
                <nz-form-control>
                    <textarea nz-input rows="3" formControlName="reason" placeholder="Descreva o motivo"></textarea>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <nz-space class="full-width">
        <button *nzSpaceItem nz-button (click)="onCancelClick()">
            Cancelar
        </button>
        <ng-container *ngIf="!alert.escalated && !userRoles.includes('resolve-escalated-alerts')">
            <button *nzSpaceItem nz-button (click)="onEscalateClick()" [nzLoading]="loading.escalate">
                <span nz-icon nzType="up"></span>
                Escalar
            </button>
        </ng-container>
        <button *nzSpaceItem nz-button (click)="onSubmit()" [disabled]="formGroup.invalid" [loading]="loading.submit"
            nzType="primary">
            Resolver
        </button>
    </nz-space>
</div>