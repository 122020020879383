import { Hydrator } from '../classes';
import { ArrivalModel } from './arrival.model';

export class TripStatusModel extends ArrivalModel {
    AU?: string;
    servicePlate?: string;


    static deserialize(jsonObject: object): TripStatusModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            lastPassArrival: Hydrator.timestampToDateTime,
            lastPassDeparture: Hydrator.timestampToDateTime,
            lastUpdate: Hydrator.timestampToDateTime,
            meanArrival: Hydrator.secondsToDuration,
            meanDeparture: Hydrator.secondsToDuration,
            predictionArrival: Hydrator.secondsToDuration,
            predictionDeparture: Hydrator.secondsToDuration
        };

        const keyTransformer = {
            route: 'routeId',
            stop: 'stopId',
            trip: 'tripId',
            chapa: 'servicePlate',
            au: 'AU',
            tickets: 'passengers'
        };

        const tripStatus = Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new TripStatusModel());

        return tripStatus;
    }
}


