export enum DirectionType {
    Return = 0,
    Outward = 1
}

export function fromNumber(type: number): DirectionType {
    switch (type) {
        case 0:
            return DirectionType.Return;
        case 1:
            return DirectionType.Outward;
    }
}
