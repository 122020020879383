import { DateTime } from 'luxon';
import { Hydrator } from '../classes';

export class ViewerModel {

    user: object;
    time: DateTime;

    static deserialize(jsonObject: object): ViewerModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            time: Hydrator.timestampToDateTime
        };

        const keyTransformer = {
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new ViewerModel());
    }
}
