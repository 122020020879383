import { Hydrator } from '../classes';


export class AreaModel {

    id: number;
    name: string;
    polygon: google.maps.LatLng[];

    static deserialize(jsonObject: object): AreaModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            geometry: Hydrator.fromArray(Hydrator.arrayToLatLng)
        };

        const keyTransformer = {
            geometry: 'polygon'
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new AreaModel());
    }
}
