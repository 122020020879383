import { DateTime } from 'luxon';
import { Hydrator } from '../classes';
import { AlertStateEnum } from './alert-state.enum';
import { AlertTypeEnum } from './alert-type.enum';
import { ProximityAlertModel } from './proximity-alert.model';
import { PunctualityAlertModel } from './punctuality-alert.model';
import { RouteDeviationAlertModel } from './route-deviation-alert.model';
import { SameScheduleAlertModel } from './same-schedule-alert.model';
import { ViewerModel } from './viewer.model';

export class AlertModel {

    id: string;
    time: DateTime;
    state: AlertStateEnum;
    type: AlertTypeEnum;
    viewers: ViewerModel[];
    escalated: boolean;
    details?: any;
    resolution?: object;

    static deserialize(jsonObject: object): AlertModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const detailsDeserialize = AlertModel.alertDetailDeserialize(jsonObject['type']);

        if (jsonObject['viewers']) {
            jsonObject['viewers'] = jsonObject['viewers'].map(v => ViewerModel.deserialize(v));
        }

        if (jsonObject['resolution'] && jsonObject['resolution']['time']) {
            jsonObject['resolution']['time'] = Hydrator.timestampToDateTime(jsonObject['resolution']['time']);
        }

        const mapProperties = {
            time: Hydrator.timestampToDateTime,
            details: detailsDeserialize
        };

        const keyTransformer = {
            AlertState: 'state'
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new AlertModel());
    }

    static alertDetailDeserialize = type => {
        switch (type) {
            case AlertTypeEnum.ROUTE_DEVIATION:
                return RouteDeviationAlertModel.deserialize;
            case AlertTypeEnum.PROXIMITY:
                return ProximityAlertModel.deserialize;
            case AlertTypeEnum.SAME_SCHEDULE:
                return SameScheduleAlertModel.deserialize;
            default:
                return PunctualityAlertModel.deserialize;
        }
    };

}
