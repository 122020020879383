import { Pipe, PipeTransform } from '@angular/core';
import { TripModel } from '../../../models';

@Pipe({
    name: 'tripFormat'
})
export class TripFormatPipe implements PipeTransform {

    transform(trip: TripModel) {
        if (!trip || !trip.startTime || !trip.endTime) {
            return 'n/a';
        }
        const startTime = trip.startTime.split(':');
        const endTime = trip.endTime.split(':');
        return `${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]}`;
    }

}
