import { Injectable } from '@angular/core';
import { RouteModel } from 'app/models/route.model';
import { RoutesService } from 'app/services/routes/routes.service';
import { StorageService } from 'app/services/storage/storage.service';
import { reject } from 'lodash-es';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';

interface IMonitoringUpdate {
    allRoutes: RouteModel[];
    addedRoute?: RouteModel;
    removedRoute?: RouteModel;
}

const MONITORING_STORAGE_KEY = 'SAE-WEBAPP/routesUnderMonitoring';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {

    private serviceInitialized = false;
    private underMonitoring = new Array<RouteModel>();
    private underMonitoringSubject = new BehaviorSubject<IMonitoringUpdate>(undefined);


    constructor(
        private storageService: StorageService,
        private routesService: RoutesService
    ) {
    }

    get underMonitoringRoutes() {
        return this.underMonitoringSubject.asObservable().pipe(filter(value => !!value));
    }

    init(): void {
        if (this.serviceInitialized) {
            return;
        }
        const routesUnderMonitoring = this.storageService.get(MONITORING_STORAGE_KEY, []);
        forkJoin(routesUnderMonitoring.map(routeId => this.routesService.getRoute(routeId)))
            .pipe(take(1))
            .subscribe(routes => this.setRoutes(routes));
        this.serviceInitialized = true;
    }

    setRoutes(routes: RouteModel[]) {
        this.underMonitoring = [...routes];
        this.underMonitoringSubject.next({ allRoutes: this.underMonitoring });
    }

    addRoute(route: RouteModel) {
        if (this.underMonitoring.some(elem => elem.id === route.id)) return;

        this.underMonitoring.unshift(route);
        this.underMonitoringSubject.next({ allRoutes: this.underMonitoring, addedRoute: route });
        this.updateStorage();
    }

    removeRoute(route: RouteModel) {
        this.underMonitoring = reject(this.underMonitoring, route.compareTo);
        this.underMonitoringSubject.next({ allRoutes: this.underMonitoring, removedRoute: route });
        this.updateStorage();
    }

    private updateStorage(): void {
        this.storageService.set(MONITORING_STORAGE_KEY, this.underMonitoring.map(r => r.id));
    }
}
