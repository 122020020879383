import { Hydrator } from '../classes';


export class StopModel {

    id: number;
    name: string;
    latitude: number;
    longitude: number;
    position: object;
    zoneId: number;
    commitmentStop?: boolean;


    static deserialize(jsonObject: object): StopModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        jsonObject['position'] = Hydrator.toLatLng(jsonObject);

        const mapProperties = {
            latitude: parseFloat,
            longitude: parseFloat
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties), new StopModel());
    }

}
