import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

/**
 * @deprecated
 */
@Pipe({
    name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {

    transform(duration: Duration, format = 'hh:mm', emptyString = '-'): string {
        if (!duration) {
            return emptyString;
        }
        return duration.toFormat(format);
    }
}
