import { Hydrator } from '../classes';
import { fromNumber } from './direction-type.model';

export class SameScheduleAlertModel {

    route: object;
    trip: object[];
    bus: object[];
    AU: string[];
    servicePlate: string[];

    static deserialize(jsonObject: object): SameScheduleAlertModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
        };

        const keyTransformer = {
            chapa: 'servicePlate'
        };

        if (jsonObject['bus']) {
            jsonObject['bus'].forEach(b => {
                if (b['position']) {
                    b['position'] = Hydrator.toLatLng(b['position']);
                }
            });
        }

        if (jsonObject['trip']) {
            jsonObject['trip'].forEach(t => {
                t['direction'] = fromNumber(t['direction']);
            });
        }

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new SameScheduleAlertModel());
    }

}
