import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlsConstants } from 'app/constants/api-urls.constants';

@Injectable()
export class RoutesControlService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getPhysicalDistance(busID: string, routeId: number, direction: number) {
        const params = new HttpParams({
            fromObject: {
                routeId,
                direction
            }
        });

        return this.httpClient
            .get(apiUrlsConstants.routesControl.getBusPhysicalDistance(busID), { params });
    }

}
