import { Pipe, PipeTransform } from '@angular/core';
import { formatFunctions } from 'app/utilities';


@Pipe({
    name: 'alertTypeFormat'
})
export class AlertTypeFormatPipe implements PipeTransform {

    transform = formatFunctions.alertyTypeFormat.bind(this);

}
