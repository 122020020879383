import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[saeClipboardButton]'
})
export class ClipboardButtonDirective implements AfterViewInit {

    @Input() content: string;

    private _htmlElement: HTMLButtonElement;
    private _iconElement: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this._htmlElement = el.nativeElement;
    }

    ngAfterViewInit(): void {
        this.renderer.addClass(this._htmlElement, 'sae-clipboard-button');

        this._iconElement = this.renderer.createElement('i');
        this.renderer.appendChild(this._htmlElement, this._iconElement);

        this._iconElement.classList.add('fa-regular', 'fa-copy');
    }

    @HostListener('click', ['$event'])
    onClick($event: Event) {
        $event.stopPropagation();
        if (this.content) {
            navigator.clipboard.writeText(this.content)
                .then(() => {
                    this._iconElement.classList.remove('fa-regular', 'fa-copy');
                    this._iconElement.classList.add('fa-solid', 'fa-check-circle');
                });
        }
    }

    @HostListener('mouseleave', ['$event'])
    onMouseLeave($event: Event) {
        $event.stopPropagation();
        this._iconElement.classList.add('fa-regular', 'fa-copy');
        this._iconElement.classList.remove('fa-solid', 'fa-check-circle');
    }

}
