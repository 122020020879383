import { Hydrator } from '../classes';

export class CalendarModel {

    id: number;
    description: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    // startDate": "2018-10-29T00:00:00.000Z",
    // endDate": "2019-04-30T00:00:00.000Z",


    static deserialize(jsonObject: object): CalendarModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, {}), new CalendarModel());
    }

}
