export class StorageModel {

    value: any;
    expiry: number;


    constructor(value: any, expiry?: number) {
        this.value = value;

        if (expiry) {
            this.expiry = expiry;
        }
    }
}
