import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { configureScope } from '@sentry/angular-ivy';
import { AlertsSidebarComponent } from 'app/components';
import { AlertsService } from 'app/services';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { take } from 'rxjs';
import { NavigationItem } from './navigation-item.model';

@Component({
    selector: 'sae-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    @Input() links: NavigationItem[] = [];

    @ViewChild('userDropdown') userDropdownRef: ElementRef;

    userProfile: KeycloakProfile = {};
    userRoles = [];
    canResolveAlerts: boolean;
    showUserDropdown = false;
    userInfoTitle = '';
    userInitials = '';
    alertsSidebarRef: AlertsSidebarComponent;
    allowedLinks = [];
    notViewedAlertsCount: number;

    constructor(
        private readonly keycloak: KeycloakService,
        private readonly alertsService: AlertsService,
        public route: Router
    ) {
        this.alertsService.sidebarRef.pipe(take(1)).subscribe(ref => this.alertsSidebarRef = ref);
        this.alertsService.notViewedCount
            .pipe(takeUntilDestroyed())
            .subscribe(notViewedCount => this.notViewedAlertsCount = notViewedCount);
    }

    ngOnInit(): void {
        const requiredRoles = ['resolve-alerts', 'resolve-escalated-alerts'];
        this.keycloak.loadUserProfile().then(userProfile => {
            this.userProfile = userProfile;
            this.userInfoTitle = [userProfile.firstName, userProfile.lastName].join(' ');
            this.userRoles = this.keycloak.getUserRoles();
            this.canResolveAlerts = this.userRoles.some(role => requiredRoles.includes(role));
            if (userProfile.firstName && userProfile.lastName) {
                this.userInitials = (userProfile.firstName[0] + userProfile.lastName[0]).toUpperCase();
            } else if (userProfile.firstName) {
                this.userInitials = this.userProfile.firstName.substring(0, 2).toUpperCase();
            } else {
                this.userInitials = this.userProfile.username.substring(0, 2).toUpperCase();
            }

            this.allowedLinks = this.links.filter(link => link.allowedRoles.some(role => this.userRoles.includes(role)));
        });
    }

    logout(): void {
        this.keycloak.logout(location.origin);
        configureScope(scope => scope.setUser(null));
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event) {
        if (!this.userDropdownRef.nativeElement.contains(event.target)) this.showUserDropdown = false;
    }
}
