import { Pipe, PipeTransform } from '@angular/core';
import { formatFunctions } from 'app/utilities';

@Pipe({
    name: 'statusFormat'
})
export class StatusFormatPipe implements PipeTransform {

    transform(...args): any {
        return formatFunctions.statusFormat.apply(null, args);
    }

}
