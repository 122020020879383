import { Component, DestroyRef, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BusState } from 'app/models';
import { MonitoringService } from 'app/modules/monitoring/services/monitoring.service';
import { HistoryService } from 'app/services';
import { ArrivalsService } from 'app/services/arrivals/arrivals.service';
import { RealTimeService } from 'app/services/real-time/real-time.service';
import { RoutesService } from 'app/services/routes/routes.service';
import { tripFormat } from 'app/utilities/format-functions';
import { keyBy, orderBy } from 'lodash-es';
import { of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

interface Item {
    state: BusState,
    label: string,
    value?: string,
    color: string,
    subItems: { busId: string, routeId?: number, tripDesc?: string, insideAgency?: boolean }[]
}

const initialItems: Item[] = [
    { state: BusState.ONSERVICE, label: 'Autocarros em serviço', color: '', subItems: [] },
    { state: BusState.ONTIME, label: 'A tempo', color: 'green', subItems: [] },
    { state: BusState.LATE, label: 'Atrasado', color: 'red', subItems: [] },
    { state: BusState.INADVANCE, label: 'Adiantado', color: 'blue', subItems: [] },
    { state: BusState.EMPTY, label: 'Vazio', color: 'grey', subItems: [] }
];

function inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    const x = point[0], y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0], yi = vs[i][1];
        const xj = vs[j][0], yj = vs[j][1];

        const intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}

@Component({
    selector: 'sae-subheader',
    templateUrl: './subheader.component.html',
    styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {

    @ViewChild('menu') menuRef: ElementRef;

    items = initialItems;
    showSliders = new Map<string, boolean>();
    itemTrackBy = (item: Item) => item.state;
    subItemTrackBy = (subItem: Item['subItems'][0]) => subItem.busId;

    private agencyArea = undefined;
    private realTimeService = inject(RealTimeService);
    private arrivalsService = inject(ArrivalsService);
    private routesService = inject(RoutesService);
    private monitoringService = inject(MonitoringService);
    private router = inject(Router);
    private historyService = inject(HistoryService);
    destroyRef = inject(DestroyRef);

    ngOnInit(): void {
        this.historyService.getAgencyPark()
            .pipe(map(value => value['coordinates'].map(e => ([e.lon, e.lat]))))
            .subscribe(value => this.agencyArea = value);
        zip(this.realTimeService.busPositions, this.arrivalsService.busesStates)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(([allBuses, busesStatus]) => {
                    const filter = {
                        where: {
                            or: Array.from(allBuses.values())
                                .filter(o => !!o.tripId)
                                .map(o => ({ id: o.tripId }))
                        }
                    };
                    return zip(
                        of(allBuses),
                        of(busesStatus),
                        filter.where.or.length > 0 ? this.routesService.getTrips(filter) : of([])
                    );
                })
            )
            .subscribe(([_allBuses, _busesStatus, _trips]) => {
                const trips = keyBy(_trips, 'id');
                const mapSubItem = ({ busId, routeId, tripId }) => ({
                    busId, routeId,
                    tripDesc: tripId && tripFormat(trips[tripId])
                });

                const onServiceBuses: Item['subItems'] = _allBuses.map(mapSubItem);
                const onTimeBuses: Item['subItems'] = _busesStatus.get(BusState.ONTIME).map(mapSubItem);
                const delayedBuses: Item['subItems'] = _busesStatus.get(BusState.LATE).map(mapSubItem);
                const inAdvanceBuses: Item['subItems'] = _busesStatus.get(BusState.INADVANCE).map(mapSubItem);
                const _emptyBuses = _allBuses.filter(o => !o.tripId);
                let insideAgency = 0;
                if (this.agencyArea)
                    insideAgency = _emptyBuses.reduce(
                        (acc, curr) => acc + (inside([curr.position.lng(), curr.position.lat()], this.agencyArea) ? 1 : 0),
                        0
                    );
                const emptyBuses: Item['subItems'] = _emptyBuses.map(mapSubItem);

                this.items = [
                    {
                        ...initialItems[0],
                        value: `${onServiceBuses.length}`,
                        subItems: orderBy(onServiceBuses, 'busId', 'asc')
                    },
                    {
                        ...initialItems[1],
                        value: `${onTimeBuses.length}`,
                        subItems: orderBy(onTimeBuses, 'busId', 'asc')
                    },
                    {
                        ...initialItems[2],
                        value: `${delayedBuses.length}`,
                        subItems: orderBy(delayedBuses, 'busId', 'asc')
                    },
                    {
                        ...initialItems[3],
                        value: `${inAdvanceBuses.length}`,
                        subItems: orderBy(inAdvanceBuses, 'busId', 'asc')
                    },
                    {
                        ...initialItems[4],
                        value: `${emptyBuses.length - insideAgency}/${emptyBuses.length}`,
                        subItems: orderBy(emptyBuses, 'busId', 'asc')
                    }
                ];
            });
    }

    toggleShowSlider(key: string) {
        this.showSliders.set(key, !this.showSliders.get(key));
    }

    hideAllSliders() {
        const keys = Array.from(this.showSliders.keys());
        keys.forEach(key => this.showSliders.set(key, false));
    }

    onItemClick(busInfo) {
        if (busInfo.routeId) {
            this.routesService.getRoute(busInfo.routeId, true)
                .pipe(take(1))
                .subscribe(route => {
                    this.monitoringService.addRoute(route);
                    this.router.navigate(['monitoring'], { fragment: `route-${route.id}` });
                    this.hideAllSliders();
                });
        }
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event) {
        if (!this.menuRef.nativeElement.contains(event.target)) this.hideAllSliders();
    }

}
