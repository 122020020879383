import { animate, animation, keyframes, style } from '@angular/animations';

export const shakeAnimation = animation(
    animate(
        '{{ timing }}s {{ delay }}s',
        keyframes([
            style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
            style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.1 }),
            style({ transform: 'translate3d(2px, 0, 0)', offset: 0.2 }),
            style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.3 }),
            style({ transform: 'translate3d(4px, 0, 0)', offset: 0.4 }),
            style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.5 }),
            style({ transform: 'translate3d(4px, 0, 0)', offset: 0.6 }),
            style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.7 }),
            style({ transform: 'translate3d(2px, 0, 0)', offset: 0.8 }),
            style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.9 }),
            style({ transform: 'translate3d(0, 0, 0)', offset: 1 })
        ])
    ),
    { params: { timing: 1, delay: 0 } }
);
