<div class="sae-forbidden-page">
    <div class="sae-main-container container-xl">
        <div class="sae-header flex-nowrap justify-start">
            <h5>Acesso restrito</h5>
        </div>
        <section class="sae-section items-center text-center emptySelection">
            <h6 class="mt-4">Não tem acesso a esta página</h6>
            <span class="text-muted mb-3">
                Devido a restrições de segurança, o acesso a esta página foi recusado.
                Por favor, volte atrás para continuar a navegação.
            </span>
            <ng-lottie height="300px" [options]="{ path: 'assets/lottie/forbidden.json', loop: false }">
            </ng-lottie>
        </section>
    </div>
</div>