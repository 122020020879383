import { Hydrator } from '../classes';
import { DirectionType, fromNumber } from './direction-type.model';
import { RouteModel } from './route.model';

export class TripModel {

    id: string;
    headsign: string;
    direction: DirectionType;
    shapeId: string;
    startTime: string;
    endTime: string;
    routeId: string;
    serviceId: number;
    route: RouteModel;
    kilometers: number;


    static deserialize(jsonObject: object): TripModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            route: RouteModel.deserialize,
            direction: fromNumber,
            kilometers: parseFloat
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties), new TripModel());
    }

}
