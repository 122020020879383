import { Expose, plainToClass } from 'class-transformer';

export class User {

    @Expose() id: string;
    @Expose() username: string;
    @Expose() firstName: string;
    @Expose() lastName: string;
    @Expose() email: string;
    @Expose() emailVerified: boolean;
    @Expose() enabled: boolean;

    static deserialize(jsonObject: object) {
        return plainToClass(User, jsonObject, { excludeExtraneousValues: true });
    }

}
