import { AlertTypeEnum } from 'app/models';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { alertyTypeFormat } from './format-functions';

export function loadingObserver<T>(loadingObj, key: string): (source: Observable<T>) => Observable<T> {
    return source => {
        loadingObj[key] = true;
        return source.pipe(tap(
            () => loadingObj[key] = false,
            () => loadingObj[key] = false
        ));
    };
}

export function roundDateTimeToMin(date: DateTime): DateTime {
    if (!date) {
        return;
    }
    return date.plus({ seconds: 30 }).startOf('minute');
}

export const dateParser = (value: string) => DateTime.fromISO(value).toJSDate();
export const dateSerializer = (value: Date) => DateTime.fromJSDate(value).toISODate();
export const dateRangeParser = (values: string) => {
    const strRange = values.split(',');
    if (strRange.length !== 2) return;
    return strRange.map(dateParser);
};
export const dateRangeSerializer = (values: Date[]) => {
    if (values.length !== 2 || values[0] == undefined || values[1] == undefined) return;
    return values.map(dateSerializer).join(',');
};

export * as formValidators from './form-validators';
export * as formatFunctions from './format-functions';

export const AlertTypeList = Object.values(AlertTypeEnum)
    .filter(k => isNaN(Number(k)) === false)
    .map(key => ({
        id: key,
        name: alertyTypeFormat(key as AlertTypeEnum)
    }));
