import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Hydrator } from 'app/classes';
import { User } from 'app/models';
import environment from 'environments';
import { keyBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';


@Injectable()
export class AuthService {

    private BASE_URL = `${environment.keycloakConfig.url}/admin/realms/${environment.keycloakConfig.realm}`;

    public idOfClient: string;
    public clientRoles: object;

    constructor(private httpClient: HttpClient) {
        this.getIdOfClient(environment.keycloakConfig.clientId)
            .pipe(
                take(1),
                tap(idOfClient => this.idOfClient = idOfClient),
                switchMap(idOfClient => this.getClientRoles(idOfClient))
            )
            .subscribe(clientRoles => {
                const notHiddenRoles = clientRoles.filter(role => !role['attributes']['hidden'] || role['attributes']['hidden'][0] !== 'true');
                this.clientRoles = keyBy(notHiddenRoles, 'name');
            });
    }

    private getIdOfClient(clientId: string): Observable<string> {
        return this.httpClient.get<object[]>(`${this.BASE_URL}/clients?clientId=${clientId}`)
            .pipe(map(arr => arr[0]['id']));
    }

    private getClientRoles(idOfClient: string): Observable<object[]> {
        const params = {
            briefRepresentation: 'false'
        };
        return this.httpClient.get<object[]>(`${this.BASE_URL}/clients/${idOfClient}/roles`, { params });
    }

    getUsers(): Observable<User[]> {
        return this.httpClient.get<object[]>(`${this.BASE_URL}/users`)
            .pipe(map(jsonArray => Hydrator.fromArray(User.deserialize)(
                jsonArray.filter(obj => !obj['attributes'] || !obj['attributes']['protected'])
            )));
    }

    getUserClientRoles(userId: string): Observable<string[]> {
        return this.httpClient.get<object[]>(`${this.BASE_URL}/users/${userId}/role-mappings/clients/${this.idOfClient}`)
            .pipe(map(arr => arr.map(elem => elem['name'])));
    }

    createUser(body: object): Observable<string> {
        return this.httpClient.post(`${this.BASE_URL}/users`, body, { observe: 'response' })
            .pipe(map(response => {
                const location = response.headers.get('Location');
                return location.split('/').pop();
            }));
    }

    updateUser(userId: string, body: object) {
        return this.httpClient.put(`${this.BASE_URL}/users/${userId}`, body);
    }

    addRolesToUser(userId: string, roles: string[]) {
        return this.httpClient.post(`${this.BASE_URL}/users/${userId}/role-mappings/clients/${this.idOfClient}`,
            roles.map(role => this.clientRoles[role])
        );
    }

    removeRolesFromUser(userId: string, roles: string[]) {
        return this.httpClient.delete(`${this.BASE_URL}/users/${userId}/role-mappings/clients/${this.idOfClient}`, {
            body: roles.map(role => this.clientRoles[role])
        });
    }

    sendVerificationEmail(userId: string) {
        return this.httpClient.put(`${this.BASE_URL}/users/${userId}/send-verify-email`, null, {
            params: {
                client_id: environment.keycloakConfig.clientId,
                redirect_uri: location.origin
            }
        });
    }

    sendUpdateAccountEmail(userId: string, actions: string[]) {
        return this.httpClient.put(`${this.BASE_URL}/users/${userId}/execute-actions-email`, actions, {
            params: {
                client_id: environment.keycloakConfig.clientId,
                redirect_uri: location.origin
            }
        });
    }
}

