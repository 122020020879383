import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AlertInfoComponent } from 'app/components/alert-info/alert-info.component';
import { AlertMapComponent } from 'app/components/alert-map/alert-map.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { LottieModule } from 'ngx-lottie';
import { BusInfoComponent, IconPinComponent, TabBarComponent, TableComponent } from './components';
import { AttrsDirective, ClipboardButtonDirective, ColTemplateDirective, LoadingDirective } from './directives';
import {
    AlertTypeFormatPipe, DateTimeFormatPipe, DirectionFormatPipe, DurationFormatPipe, DynamicPipe, PassengerFormatPipe, StatusFormatPipe, TripFormatPipe
} from './pipes';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
    declarations: [
        IconPinComponent,
        LoadingDirective,
        ClipboardButtonDirective,
        AttrsDirective,
        ColTemplateDirective,
        BusInfoComponent,
        TabBarComponent,
        DirectionFormatPipe,
        DateTimeFormatPipe,
        DurationFormatPipe,
        StatusFormatPipe,
        TripFormatPipe,
        PassengerFormatPipe,
        DynamicPipe,
        AlertTypeFormatPipe,
        AlertInfoComponent,
        AlertMapComponent,
        TableComponent,
        ColTemplateDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        LottieModule,
        GoogleMapsModule,
        SvgIconComponent,
        NzToolTipModule,
        NzTableModule,
        NzIconModule,
        NzButtonModule,
        NzSelectModule,
        NzModalModule,
        NzInputModule,
        NzSpaceModule,
        NzDatePickerModule,
        NzFormModule,
        NzCheckboxModule,
        NzPopoverModule,
        NzRadioModule,
        NzTagModule,
        NzSpinModule
    ],
    exports: [
        BusInfoComponent,
        CommonModule,
        GoogleMapsModule,
        LottieModule,
        FormsModule,
        ReactiveFormsModule,
        NzTableModule,
        NzButtonModule,
        NzToolTipModule,
        NzIconModule,
        NzSelectModule,
        NzModalModule,
        NzCheckboxModule,
        NzFormModule,
        NzDatePickerModule,
        NzSpaceModule,
        NzInputModule,
        NzPopoverModule,
        NzRadioModule,
        NzTagModule,
        NzSpinModule,
        SvgIconComponent,
        IconPinComponent,
        LoadingDirective,
        AttrsDirective,
        ClipboardButtonDirective,
        TabBarComponent,
        DynamicPipe,
        DirectionFormatPipe,
        DateTimeFormatPipe,
        DurationFormatPipe,
        StatusFormatPipe,
        TripFormatPipe,
        PassengerFormatPipe,
        AlertTypeFormatPipe,
        AlertInfoComponent,
        AlertMapComponent,
        TableComponent,
        ColTemplateDirective
    ]
})
export class SharedModule {
}
