import { AlertModel } from '.';
import { Hydrator } from '../classes';

export class AlertPageModel {

    data: AlertModel[];
    total: number;

    static deserialize(jsonObject: object): AlertPageModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            data: Hydrator.fromArray(AlertModel.deserialize)
        };

        const keyTransformer = {
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new AlertPageModel());
    }
}
