import { Hydrator } from '../classes';
import { fromNumber } from './direction-type.model';

export class ProximityAlertModel {

    route: object;
    trip: object[];
    bus: object[];
    stop: object;
    AU: string[];
    servicePlate: string[];
    proximityTime: number;


    static deserialize(jsonObject: object): ProximityAlertModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
        };

        const keyTransformer = {
            chapa: 'servicePlate'
        };

        if (jsonObject['bus']) {
            jsonObject['bus'].forEach(b => {
                if (b['position']) {
                    b['position'] = Hydrator.toLatLng(b['position']);
                }
            });
        }

        if (jsonObject['trip']) {
            jsonObject['trip'].forEach(t => {
                t['direction'] = fromNumber(t['direction']);
            });
        }

        if (jsonObject['proximityTime']) {
            jsonObject['proximityTime'] = Hydrator.secondsToDuration(jsonObject['proximityTime']);
        }

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new ProximityAlertModel());
    }

}
