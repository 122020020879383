import { Component } from '@angular/core';

@Component({
    selector: 'sae-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

	showBugTriggerDialog() {
		(window as any).showBugTriggerDialog();
	}

	showFeedbackTriggerDialog() {
		(window as any).showFeedbackTriggerDialog();
	}

}

