import { animate, state, style, transition } from '@angular/animations';

export function shrinkOutAnimation() {
    return [
        state('in', style({ height: '*' })),
        transition('* => void', [
            style({ height: '*' }),
            animate('150ms', style({ height: 0 }))
        ])
    ];
}
