import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { fromEvent, map, merge, of } from 'rxjs';
import { RoutesService } from './services/routes/routes.service';


@Component({
    selector: 'sae-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    networkStatus = true;

    private routesService = inject(RoutesService);
    private destroyRef = inject(DestroyRef);
    private notificationService = inject(NzNotificationService);


    ngOnInit() {
        this.routesService.init();
        this.checkNetworkStatus();
    }

    private checkNetworkStatus() {
        this.networkStatus = navigator.onLine;
        merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map(() => navigator.onLine)
            )
            .subscribe(value => {
                if (value === false) {
                    this.networkStatus = value;
                    this.notificationService.error(
                        'Perda de conexão',
                        'Foi detetada uma perda de conexão à internet. Por favor verifique a estabilidade da ligação.',
                        { nzDuration: 0 }
                    ).messageId;
                } else if (this.networkStatus === false) {
                    // Recover from network failure
                    window.location.reload();
                }
            });
    }

}
