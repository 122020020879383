import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import environment from 'environments';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard  {

    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService
    ) { }

    canActivate(): boolean {
        let defaultRoute = '/monitoring';
        const roles = this.keycloak.getUserRoles();
        roles.forEach(role => {
            if (environment.roleDefaultRoute[role]) defaultRoute = environment.roleDefaultRoute[role];
        });
        this.router.navigate([defaultRoute]);
        return true;
    }

}