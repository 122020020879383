<div class="d-flex">
    <h6 style="flex: 70%" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
    </h6>
    <h6 style="flex: 30%" class="uppercase justify-end" saeClipboardButton [content]="alert.id">
        #{{alert.id.substr(alert.id.length - 3)}}
    </h6>
</div>
<div [class]="options.messageClass" [attr.aria-label]="message">
    <sae-alert-info *ngIf="alert" [alert]="alert"></sae-alert-info>
</div>
<div class="buttons-container">
    <button nz-button (click)="remove()">
        <span nz-icon nzType="close" nzTheme="outline"></span> Ignorar
    </button>

    <button nz-button (click)="onResolveClick()" [nzLoading]="isLoading" [disabled]="alertState === 'locked'"
        nzType="primary">
        <span nz-icon nzType="check" nzTheme="outline"></span> Resolver
    </button>
</div>

<div class="error-message" [@shake]="alertState" *ngIf="alertState === 'locked'">
    <i class="fas fa-lock mr-1"></i>Alerta já em resolução
</div>

<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>