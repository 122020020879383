import { Component, Input, OnInit } from '@angular/core';
import { AlertModel, AlertTypeEnum } from 'app/models';
import { Duration } from 'luxon';

@Component({
    selector: 'sae-alert-info',
    templateUrl: './alert-info.component.html',
    styleUrls: ['./alert-info.component.scss']
})
export class AlertInfoComponent implements OnInit {

    @Input() alert: AlertModel;

    public information: string;
    public alertTypeEnum = AlertTypeEnum;

    ngOnInit(): void {
        switch (this.alert.type) {
            case AlertTypeEnum.SIGNIFICANT_DELAY: {
                const time = this.getTimeDiference(this.alert);
                this.information = `O autocarro chegou ${time} ${time !== 1 ? 'minutos' : 'minuto'} atrasado.`;
                break;
            }
            case AlertTypeEnum.EARLY_DEPARTURE: {
                const time = this.getTimeDiference(this.alert);
                this.information = `O autocarro saiu ${time} ${time !== 1 ? 'minutos' : 'minuto'} adiantado da paragem.`;
                break;
            }
            case AlertTypeEnum.PROXIMITY: {
                const time = this.alert.details['proximityTime'] as Duration;
                time && (this.information = `Os autocarros estavam com uma proximidade de ${Math.round(time.as('minutes'))} minutos.`);
                break;
            }
            case AlertTypeEnum.MISSING_CIRCULATION: {
                this.information = `O horário referido no alerta (ID: ${this.alert.details['trip'][0].id}) não foi realizado.`;
                break;
            }
            case AlertTypeEnum.EXCESSIVE_DRIVING: {
                const time = this.alert.details['drivingLimit'];
                const timeStr = time < 3600 ? `${Math.round(time / 60)} minuto(s) consecutivo(s)` : `${Math.round(time / 3600)} hora(s) consecutiva(s)`;
                this.information = `O motorista conduziu mais de ${timeStr}.`;
                break;
            }
        }
    }


    getTimeDiference(alert) {
        const time = alert.details.bus[0]['delay'];
        return Math.abs(Math.round(time / 60));
    }
}
