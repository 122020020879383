import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Hydrator } from 'app/classes/hydrator';
import { apiUrlsConstants } from 'app/constants/api-urls.constants';
import { AreaModel, BusStopServiceModel, CalendarModel, FareZoneModel, RouteModel, StopModel, TripModel } from 'app/models';
import { keyBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class RoutesService {

    private routes: Record<string, RouteModel> = {};

    constructor(
        private httpClient: HttpClient
    ) { }

    init(): void {
        this.getRoutes().pipe(take(1)).subscribe(routes => this.routes = keyBy(routes, 'id'));
    }

    getRoute(routeId: number, local = false): Observable<RouteModel> {
        if (local && this.routes[routeId]) {
            return of(this.routes[routeId]);
        }
        return this.httpClient
            .get(apiUrlsConstants.routes.getRoute(routeId))
            .pipe(map(RouteModel.deserialize));
    }

    getCalendars(): Observable<CalendarModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getCalendars())
            .pipe(map(Hydrator.fromArray(CalendarModel.deserialize)));
    }

    getRoutes(local = false): Observable<RouteModel[]> {
        if (local) {
            return of(Object.values(this.routes));
        }
        return this.httpClient
            .get(apiUrlsConstants.routes.getRoutes())
            .pipe(map(Hydrator.fromArray(RouteModel.deserialize)));
    }

    getRouteSpines(routeId: string, tripIds: string[], includeStops: boolean = true): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                tripIds: tripIds,
                includeStops: includeStops
            }
        });
        return this.httpClient
            .get(apiUrlsConstants.routes.getRouteSpines(routeId), { params });
    }

    getShapes(shapeIds: string[]): Observable<any> {
        const filter = { order: 'sequence', where: { shapeId: { inq: shapeIds } } };
        const params = new HttpParams().set('filter', JSON.stringify(filter));
        return this.httpClient
            .get(apiUrlsConstants.routes.getShapes(), { params })
            .pipe(map(obj => {
                Object.keys(obj).forEach(sh => obj[sh] = obj[sh].map(Hydrator.toLatLng));
                return obj;
            }));
    }

    getFareZones(): Observable<FareZoneModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getFareZones())
            .pipe(map(Hydrator.fromArray(FareZoneModel.deserialize)));
    }

    getAreas(): Observable<AreaModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getAreas())
            .pipe(map(Hydrator.fromArray(AreaModel.deserialize)));
    }

    getStops(filter?: object): Observable<StopModel[]> {
        const params = filter ? { filter: JSON.stringify(filter) } : {};
        return this.httpClient
            .get(apiUrlsConstants.routes.getStops(), { params })
            .pipe(map(Hydrator.fromArray(StopModel.deserialize)));
    }

    getBusStopRoutes(stopId: number): Observable<BusStopServiceModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getBusStopRoutes(stopId))
            .pipe(map(Hydrator.fromArray(AreaModel.deserialize)));
    }

    getTrips(filter?: object): Observable<TripModel[]> {
        const params = filter ? { filter: JSON.stringify(filter) } : {};
        return this.httpClient
            .get(apiUrlsConstants.routes.getTrips(), { params })
            .pipe(map(Hydrator.fromArray(TripModel.deserialize)));
    }

    getTripwithRoute(tripId: string): Observable<TripModel> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getTripwithRoute(tripId))
            .pipe(map(TripModel.deserialize));

    }

    getTripStopsSequence(tripId: string): Observable<any[]> {
        return this.httpClient.get<any[]>(apiUrlsConstants.routes.getStopsSequence(tripId));
    }

    getRoutesCount(): Observable<any> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getRoutesCount());
    }

    getStopsCount(): Observable<any> {
        return this.httpClient
            .get(apiUrlsConstants.routes.getStopCount());
    }

    getHolidays() {
        return this.httpClient.get<object[]>(apiUrlsConstants.routes.holidays);
    }

    createOrUpdateHoliday(data) {
        return this.httpClient.post(apiUrlsConstants.routes.holidays, data);
    }

    deleteHoliday(id) {
        return this.httpClient.delete(`${apiUrlsConstants.routes.holidays}/${id}`);
    }

    getDateServiceId(dateInfo: string) {
        return this.httpClient.get(apiUrlsConstants.routes.serviceId, { params: { dateInfo } });
    }
}
