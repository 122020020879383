import { DateTime } from 'luxon';
import { Hydrator } from '../classes';

export class BusStopArrivalModel {

    tripId: string;
    routeId: number;
    serviceId: number;
    arrivalTime: DateTime;

    static deserialize(jsonObject: object): BusStopArrivalModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            arrivalTime: Hydrator.timestampToDateTime
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, {}), {});
    }
}
