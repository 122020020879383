<ng-container [ngSwitch]="alert.type">
    <ng-container
        *ngSwitchCase="[alertTypeEnum.EARLY_DEPARTURE, alertTypeEnum.SIGNIFICANT_DELAY, alertTypeEnum.PROXIMITY].includes(alert.type) ? alert.type : !alert.type">
        <div class="map">
            <google-map #map width="100%" height="100%" [options]="mapOptions"
                (mapInitialized)="mapInitialized($event)">
                <map-marker *ngFor="let item of alert.details.stop" #stopMarker="mapMarker"
                    [position]="item.position" [options]="busStopOptions"
                    (mapClick)="openInfoWindow(stopMarker, stopInfoWindow)">
                    <map-info-window #stopInfoWindow="mapInfoWindow">
                        <div class="stop-popover">
                            <div class="info-wrapper">
                                <i class="fas fa-hashtag"></i>
                                <div>{{ item.id }}</div>
                            </div>
                            <div class="info-wrapper">
                                <i class="fas fa-info"></i>
                                <div class="description">{{ item.name }}</div>
                            </div>
                            <div class="info-wrapper">
                                <i class="fas fa-euro-sign"></i>
                                <div>{{ item.zoneId }}</div>
                            </div>
                            <div class="info-wrapper">
                                <i class="fas fa-clock"></i>
                                <div>{{ item.time| durationFormat }}</div>
                            </div>
                        </div>
                    </map-info-window>
                </map-marker>
                <map-marker *ngFor="let item of alert.details.bus; let i = index" #busMarker="mapMarker"
                    [position]="item.position" [options]="busOptions[i]"
                    (mapClick)="openInfoWindow(busMarker, busInfoWindow)">
                    <map-info-window #busInfoWindow="mapInfoWindow">
                        <div class="bus-popover">
                            <p [ngClass]="'sae-bus-popover-delay ' + getColorStatus(item.status)">
                                <b>{{ item | statusFormat}}</b>
                            </p>
                            <p><b>Linha:</b> {{ alert.details.route.shortName }}</p>
                            <p><b>Horário:</b> {{ alert.details.trip[i] | tripFormat }}</p>
                            <p>
                                <b>Sentido:</b>
                                {{ alert.details.trip[i].direction | directionFormat }}
                            </p>
                            <p>
                                <span><b>AU:</b> {{ alert.details.AU[i] }}</span> ,
                                <span><b>Chapa:</b> {{ alert.details.servicePlate[i] }}</span>
                            </p>
                        </div>
                    </map-info-window>
                </map-marker>
            </google-map>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="alertTypeEnum.ROUTE_DEVIATION">
        <div class="map">
            <google-map #map width="100%" height="100%" [options]="mapOptions"
                (mapInitialized)="mapInitialized($event)">
                <map-marker *ngFor="let item of alert.details.bus; let i = index" #busMarker="mapMarker"
                    [position]="item.position" [options]="busOptions[i]"
                    (mapClick)="openInfoWindow(busMarker, busInfoWindow)">
                    <map-info-window #busInfoWindow="mapInfoWindow">
                        <div class="bus-popover">
                            <p [ngClass]="'sae-bus-popover-delay ' + getColorStatus(item.status)">
                                <b>{{ item | statusFormat}}</b>
                            </p>
                            <p><b>Linha:</b> {{ alert.details.route.shortName }}</p>
                            <p><b>Horário:</b> {{ alert.details.trip[i] | tripFormat }}</p>
                            <p>
                                <b>Sentido:</b>
                                {{ alert.details.trip[i].direction | directionFormat }}
                            </p>
                            <p>
                                <span><b>AU:</b> {{ alert.details.AU[i] }}</span> ,
                                <span><b>Chapa:</b> {{ alert.details.servicePlate[i] }}</span>
                            </p>
                        </div>
                    </map-info-window>
                </map-marker>
                <map-marker *ngFor="let item of tripStops" #stopMarkers="mapMarker"
                    [position]="item.position" [options]="busStopOptions"
                    (mapClick)="openInfoWindow(stopMarkers, stopsInfoWindow)">
                    <map-info-window #stopsInfoWindow="mapInfoWindow">
                        <div class="stop-popover">
                            <div class="info-wrapper">
                                <i class="fas fa-hashtag"></i>
                                <div>{{ item.stop }}</div>
                            </div>
                            <div class="info-wrapper">
                                <i class="fas fa-info"></i>
                                <div class="description">{{ item.stopName }}</div>
                            </div>
                            <div class="info-wrapper">
                                <i class="fas fa-euro-sign"></i>
                                <div>{{ item.zoneId }}</div>
                            </div>
                            <div class="info-wrapper">
                                <i class="fas fa-clock"></i>
                                <div>{{ item.time| durationFormat }}</div>
                            </div>
                        </div>
                    </map-info-window>
                </map-marker>
                <map-polyline *ngIf="showShape['estimated']" [path]="shape"
                    [options]="mapConfig.estimatedPolylineOptions">
                </map-polyline>
                <map-polyline *ngIf="showShape['real']" [path]="realShape"
                    [options]="mapConfig.realPolylineOptions">
                </map-polyline>
                <div class="hide">
                    <div #legend class="sae-legend"
                        [ngStyle]="{'display': isFullScreen ? 'block' : 'none'}">
                        <div class="sae-legend-item" (click)="toggleShowShape('real')">
                            <div class="square"
                                [ngStyle]="{'background-color': mapConfig.realPolylineOptions.strokeColor, 'opacity': showShape['real'] ? 1 : 0.6}">
                            </div>
                            <span class="sae-legend-label">Rota real</span>
                        </div>
                        <div class="sae-legend-item" (click)="toggleShowShape('estimated')">
                            <div class="square"
                                [ngStyle]="{'background-color': mapConfig.estimatedPolylineOptions.strokeColor, 'opacity': showShape['estimated'] ? 1 : 0.6 }">
                            </div>
                            <span class="sae-legend-label">Rota prevista</span>
                        </div>
                    </div>
                </div>
            </google-map>
        </div>
    </ng-container>

    <ng-container
        *ngSwitchCase="alertTypeEnum.SAME_SCHEDULE">
        <div class="map">
            <google-map #map width="100%" height="100%" [options]="mapOptions"
                (mapInitialized)="mapInitialized($event)">
                <map-marker *ngFor="let item of alert.details.bus; let i = index" #busMarker="mapMarker"
                    [position]="item.position" [options]="busOptions[i]"
                    (mapClick)="openInfoWindow(busMarker, busInfoWindow)">
                    <map-info-window #busInfoWindow="mapInfoWindow">
                        <div class="bus-popover">
                            <p [ngClass]="'sae-bus-popover-delay ' + getColorStatus(item.status)">
                                <b>{{ item | statusFormat}}</b>
                            </p>
                            <p><b>Linha:</b> {{ alert.details.route.shortName }}</p>
                            <p><b>Horário:</b> {{ alert.details.trip[0] | tripFormat }}</p>
                            <p>
                                <b>Sentido:</b>
                                {{ alert.details.trip[0].direction | directionFormat }}
                            </p>
                            <p>
                                <span><b>AU:</b> {{ alert.details.AU[i] }}</span> ,
                                <span><b>Chapa:</b> {{ alert.details.servicePlate[i] }}</span>
                            </p>
                        </div>
                    </map-info-window>
                </map-marker>
            </google-map>
        </div>
    </ng-container>
</ng-container>
<div class="hide">
    <div class="map-control fullscreen-control">
        <svg-icon *ngIf="!isFullScreen" key="ic_fullscreen" class="inline-flex" color="#464B51"
            fontSize="24px">
        </svg-icon>
        <svg-icon *ngIf="isFullScreen" key="ic_fullscreen_exit" class="inline-flex" color="#464B51"
            fontSize="24px">
        </svg-icon>
    </div>
</div>