import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AlertsSidebarComponent } from 'app/components';
import { AlertsService } from 'app/services';
import navbarLinks from './navbar-items.json';

@Component({
    selector: 'sae-main',
    templateUrl: './main.page.html',
    styleUrls: ['./main.page.scss']
})
export class MainPage implements AfterViewInit {

    @ViewChild(AlertsSidebarComponent) alertsSidebarRef: AlertsSidebarComponent;

    public links = navbarLinks;

    constructor(private alertsService: AlertsService) {
    }

    ngAfterViewInit(): void {
        this.alertsService.setSidebarRef(this.alertsSidebarRef);
    }
}
