import { GoogleMap } from '@angular/google-maps';

const stopMarkerIcon = {
    url: 'assets/img/mobibus/tooltip_bus_stop.png',
    size: new google.maps.Size(19, 24),
    scaledSize: new google.maps.Size(19, 24)
};

const stopMarkerIconHighlighted = {
    url: 'assets/img/mobibus/tooltip_bus_stop.png',
    size: new google.maps.Size(29, 37),
    scaledSize: new google.maps.Size(29, 37)
};

/* eslint-disable max-len */
const template = [
    '<?xml version="1.0" encoding="UTF-8"?>',
    '<svg width="36px" height="32px" viewBox="0 0 36 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
    '<g id="tooltip-/-monitorização-/-tooltip_mapa" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">',
    '<path d="M33,0 C34.6568542,1.39730022e-16 36,1.34314575 36,3 L36,22.053822 C36,23.7106763 34.6568542,25.053822 33,25.053822 L23.7626529,25.053822 C23.1012105,25.053822 22.482548,25.3808493 22.1100197,25.9274102 L18.8272715,30.7437472 C18.5162215,31.2001092 17.8941111,31.3179079 17.437749,31.0068579 C17.3340584,30.9361838 17.244596,30.8466252 17.1740335,30.7428588 L13.9006732,25.929187 C13.5283048,25.3815971 12.9090334,25.053822 12.2468303,25.053822 L3,25.053822 C1.34314575,25.053822 1.78282679e-13,23.7106763 0,22.053822 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L33,0 Z M31,3 L5,3 C3.8954305,3 3,3.8954305 3,5 L3,5 L3,20 C3,21.1045695 3.8954305,22 5,22 L5,22 L31,22 C32.1045695,22 33,21.1045695 33,20 L33,20 L33,5 C33,3.8954305 32.1045695,3 31,3 L31,3 Z" id="Combined-Shape" fill="{{ color }}"></path>',
    '<g id="Group" transform="translate(3.000000, 3.000000)" fill="#FFFFFF">',
    '<path d="M2,0 L28,0 C29.1045695,-2.02906125e-16 30,0.8954305 30,2 L30,17 C30,18.1045695 29.1045695,19 28,19 L2,19 C0.8954305,19 1.3527075e-16,18.1045695 0,17 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z" id="Rectangle"></path>',
    '</g>',
    '</g>',
    '</svg>'
].join('\n');
/* eslint-enable max-len */

const busMarkerIcon = color => {
    let hexColor = '#000000';
    switch (color) {
        case 'green':
            hexColor = '#009b72';
            break;
        case 'blue':
            hexColor = '#2369B3';
            break;
        case 'red':
            hexColor = '#c1292e';
            break;
        case 'grey':
            hexColor = '#929090';
            break;
        case 'default':
            hexColor = '#ffffff';
            break;
    }
    const svg = template.replace('{{ color }}', hexColor);
    return {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
        size: new google.maps.Size(36, 32),
        scaledSize: new google.maps.Size(36, 32),
        labelOrigin: { x: 18, y: 12 }
    };
};

const mapStyle = [{
    featureType: 'transit.station',
    stylers: [
        { visibility: 'off' }
    ]
}, {
    featureType: 'poi.business',
    stylers: [
        { visibility: 'off' }
    ]
}, {
    featureType: 'poi.sports_complex',
    stylers: [
        { visibility: 'off' }
    ]
}];

const mapOptions: GoogleMap['options'] = {
    zoom: 14,
    center: new google.maps.LatLng(41.539368, -8.4189747),
    styles: mapStyle,
    disableDefaultUI: true,
    controlSize: 32,
    gestureHandling: 'greedy'
};

const busStopOptions = {
    icon: stopMarkerIcon,
    zIndex: 1,
    optimized: true
};

const realPolylineOptions = {
    clickable: false,
    strokeWeight: 6,
    strokeOpacity: 0.8,
    strokeColor: '#d35400'
};
const estimatedPolylineOptions = {
    clickable: false,
    strokeWeight: 3,
    strokeOpacity: 1.0,
    strokeColor: '#26b0ff'
};

export {
    busMarkerIcon, busStopOptions, estimatedPolylineOptions, mapOptions, realPolylineOptions, stopMarkerIconHighlighted
};

