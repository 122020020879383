import { mapConfig } from 'app/constants';
import { Hydrator } from '../classes';
import { DirectionType, fromNumber } from './direction-type.model';

export class BusPositionModel {

    busId: string;
    routeId: number;
    time: number;
    direction: DirectionType;
    position: google.maps.LatLng;
    tripId: string;
    AU: string;
    servicePlate: string;
    passengers: number;
    distance: number;
    velocity: number;

    static deserialize(jsonObject: object): BusPositionModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        if (jsonObject['lat'] != undefined && jsonObject['lon'] != undefined) {
            jsonObject['position'] = { latitude: jsonObject['lat'], longitude: jsonObject['lon'] };
        }


        const mapProperties = {
            direction: fromNumber,
            position: Hydrator.toLatLng,
            speed: Math.floor,
            distance: Hydrator.meterToKm
        };

        const keyTransformer = {
            chapa: 'servicePlate',
            tickets: 'passengers',
            speed: 'velocity',
            lineId: 'routeId'
        };

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new BusPositionModel());
    }

    static toMarker(busPosition: BusPositionModel, color: string): google.maps.Marker {
        const marker = new google.maps.Marker({ position: busPosition.position });
        marker['options'] = {
            icon: mapConfig.busMarkerIcon(color),
            label: {
                text: busPosition.busId,
                fontWeight: '900',
                fontFamily: 'Roboto',
                color: '#434348',
                fontSize: '12px'
            }
        };
        return marker;
    }

}
