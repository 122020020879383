import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Hydrator } from 'app/classes';
import { apiUrlsConstants } from 'app/constants/api-urls.constants';
import { BusPositionModel } from 'app/models';
import { Observable, of, Subject, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class RealTimeService {

    private _busPositions = new Subject<BusPositionModel[]>();
    private destroyRef = inject(DestroyRef);

    constructor(
        private httpClient: HttpClient
    ) { }

    init() {
        timer(500, 3000)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(() => this._busPositions.observers.length > 0),
                switchMap(() => this._busPositions.observers.length > 0 ? this.getBusPositions() : of(undefined))
            )
            .subscribe(busPositions => {
                busPositions && this._busPositions.next(busPositions);
            });
    }

    get busPositions(): Observable<BusPositionModel[]> {
        return this._busPositions.asObservable();
    }

    getBusPositionsByRoute(routeId: number): Observable<BusPositionModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.realTime.getBusPositionsByRoute(routeId))
            .pipe(map(Hydrator.fromArray(BusPositionModel.deserialize)));
    }

    getBusPositionsById(routeId: number, busId: string): Observable<BusPositionModel> {
        return this.httpClient
            .get(apiUrlsConstants.realTime.getBusPositionsByID(routeId, busId))
            .pipe(map(BusPositionModel.deserialize));
    }

    getBusPositions(): Observable<BusPositionModel[]> {
        return this.httpClient
            .get(apiUrlsConstants.realTime.getBusPositions())
            .pipe(map(Hydrator.fromArray(BusPositionModel.deserialize)));
    }
}
