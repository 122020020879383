import { Hydrator } from '../classes';
import { fromNumber } from './direction-type.model';

export class PunctualityAlertModel {

    route: object;
    trip: object[];
    bus: object[];
    stop: object[];
    AU: string[];
    servicePlate: string[];


    static deserialize(jsonObject: object): PunctualityAlertModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
        };

        const keyTransformer = {
            chapa: 'servicePlate'
        };

        if (jsonObject['bus']) {
            jsonObject['bus'].forEach(b => {
                if (b['position']) {
                    b['position'] = Hydrator.toLatLng(b['position']);
                }
            });
        }

        if (jsonObject['trip']) {
            jsonObject['trip'].forEach(t => {
                t['direction'] = fromNumber(t['direction']);
            });
        }

        if (jsonObject['stop']) {
            jsonObject['stop'].forEach(s => {
                s['time'] = Hydrator.secondsToDuration(s['time']);
                s['position'] = Hydrator.toLatLng(s['position']);
            });
        }

        return Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new PunctualityAlertModel());
    }

}
