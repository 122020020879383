<div class="sae-footer">
    <div class="container-xl">
        <div>
            <div class="logo-text">
                <span class="title">MobiBUS SAEx</span>
                <p class="ma-0">Transportes Urbanos de Braga</p>
            </div>
        </div>

        <div class="report-links">
            <a routerLink="" (click)="showBugTriggerDialog()" id="bugTrigger">
                Reportar um problema
            </a>
            <div class="split">|</div>
            <a routerLink="" (click)="showFeedbackTriggerDialog()" id="feedbackTrigger">
                Enviar uma sugestão
            </a>
        </div>
    </div>
</div>