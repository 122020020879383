import { Component } from '@angular/core';

@Component({
    selector: 'sae-forbidden',
    templateUrl: './forbidden.page.html',
    styleUrls: ['./forbidden.page.scss']
})
export class ForbiddenPage {

}
