import { HttpClient, HttpParams } from '@angular/common/http';
import { DestroyRef, Inject, Injectable, Injector, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Hydrator } from 'app/classes';
import { AlertToastComponent, AlertsSidebarComponent } from 'app/components';
import { apiUrlsConstants } from 'app/constants';
import { AlertModel, AlertTag } from 'app/models';
import { AlertPageModel } from 'app/models/alert-page.model';
import { SSEService } from 'app/services';
import { formatFunctions } from 'app/utilities';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

const ALERTS_STORAGE_KEY = 'SAE-WEBAPP/alertsFilters';

@Injectable({ providedIn: 'root' })
export class AlertsService {

    filters: any;
    tags: AlertTag[] = [];

    private _sidebarRef = new BehaviorSubject<AlertsSidebarComponent>(undefined);
    private _alertInResolution = new BehaviorSubject<AlertModel>(undefined);
    private _alertsEvents = new Subject<any>();
    private _notViewedCount = new BehaviorSubject<number>(0);
    private _settings = new BehaviorSubject<any>(undefined);
    private destroyRef = inject(DestroyRef);

    constructor(
        private httpClient: HttpClient,
        private sseService: SSEService,
        private keycloak: KeycloakService,
        private storageService: StorageService,
        @Inject(Injector) private readonly injector: Injector
    ) {
        this.subscribeEvents();
        this.getTags()
            .pipe(take(1))
            .subscribe(tags => this.tags = tags);

        // Check roles
        const requiredRoles = ['resolve-alerts', 'resolve-escalated-alerts'];
        const userRoles = this.keycloak.getUserRoles();
        if (userRoles.some(role => requiredRoles.includes(role))) {
            this.updateNotViewedCount();
        }

        const filters = this.storageService.get(`${ALERTS_STORAGE_KEY}_${this.keycloak.getUsername()}`, {});
        if (typeof filters['sortField'] === 'string' && typeof filters['sortOrder'] === 'number' && filters['types'].length === 7) {
            this.filters = filters;
        } else {
            this.storageService.delete(`${ALERTS_STORAGE_KEY}_${this.keycloak.getUsername()}`);
        }
        this.getConfig().pipe(take(1)).subscribe(appConfig => this._setSettings(appConfig));
    }

    private get toastr() {
        return this.injector.get(ToastrService);
    }

    get alertInResolution() {
        return this._alertInResolution.asObservable().pipe(filter(elem => !!elem));
    }

    get sidebarRef() {
        return this._sidebarRef.pipe(filter(elem => !!elem), take(1));
    }

    get alertEvents(): Observable<any> {
        return this._alertsEvents.pipe(filter(elem => !!elem));
    }

    get notViewedCount(): Observable<number> {
        return this._notViewedCount.asObservable();
    }

    get settings(): Observable<any> {
        return this._settings.asObservable().pipe(filter(value => !!value));
    }

    get currentSettingsValue(): any {
        return this._settings.getValue();
    }

    private getTags(): Observable<AlertTag[]> {
        return this.httpClient.get(apiUrlsConstants.alerts.getTags())
            .pipe(map(Hydrator.fromArray(AlertTag.deserialize)));
    }

    private _setSettings(appConfig) {
        const root = document.documentElement;
        appConfig.popups.forEach(e => {
            root.style.setProperty(`--alert-priority-${e.priority}`, e.color);
        });
        this._settings.next(appConfig);
    }

    private subscribeEvents() {
        const eventTypes = [];
        if (this.keycloak.isUserInRole('resolve-escalated-alerts')) {
            eventTypes.push('alert-escalated');
        } else if (this.keycloak.isUserInRole('resolve-alerts')) {
            eventTypes.push('alert-created');
        }

        if (eventTypes.length > 0) {
            this.sseService.subscribeEvents(apiUrlsConstants.events.subscribe(), eventTypes)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(event => {
                    if (event.type === 'alert-created') this.incrementNotViewedCount();
                    // TODO: Try to avoid this request
                    if (event.type === 'alert-escalated' && event.data.viewers) {
                        const isNew = !event.data.viewers.some(elem => elem.user.name === this.keycloak.getUsername());
                        if (isNew) this.incrementNotViewedCount();
                    }

                    this._alertsEvents.next(event);
                    const alert = AlertModel.deserialize(event.data);
                    const popupConfig = AlertToastComponent.getPopupConfig(this.currentSettingsValue, alert);
                    if (popupConfig) {
                        const popup = this.toastr.show('', formatFunctions.alertyTypeFormat(alert.type), {
                            toastComponent: AlertToastComponent,
                            positionClass: 'toast-top-center',
                            ...popupConfig.options
                        });
                        popup.toastRef.componentInstance.alert = alert;
                        popup.toastRef.componentInstance.onShown = popup.onShown;
                        popup.toastRef.componentInstance.onHidden = popup.onHidden;
                        popup.toastRef.componentInstance.playAudioOnShow = popupConfig.playAudioOnShow;
                        popup.toastRef.componentInstance.showBackdrop = popupConfig.showBackdrop;
                    }
                });
        }

        this.sseService.subscribeEvents(apiUrlsConstants.events.subscribe(), ['alert-resolved'])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(event => {
                this._alertsEvents.next(event);
                const toast = this.toastr.toasts.find(toast => toast.toastRef.componentInstance.alert?.id === event.data.id);
                toast && this.toastr.remove(toast.toastId);
            });


        this.sseService.subscribeEvents(apiUrlsConstants.events.subscribe(), ['alerts-settings-updated'])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.getConfig().pipe(take(1)).subscribe(appConfig => this._setSettings(appConfig));
            });
    }

    updateNotViewedCount() {
        this.httpClient.get(apiUrlsConstants.alerts.getNotViewed())
            .pipe(
                take(1),
                map(obj => obj['notViewed'])
            )
            .subscribe(value => this._notViewedCount.next(value));
    }

    incrementNotViewedCount() {
        this._notViewedCount.next(this._notViewedCount.getValue() + 1);
    }

    updateStorage(filters): void {
        this.filters = filters;
        this.storageService.set(`${ALERTS_STORAGE_KEY}_${this.keycloak.getUsername()}`, this.filters);
    }

    setSidebarRef(ref: AlertsSidebarComponent) {
        this._sidebarRef.next(ref);
    }

    startResolution(alertId: string) {
        return this.httpClient.put(apiUrlsConstants.alerts.startResolution(alertId), null)
            .pipe(
                map(AlertModel.deserialize),
                tap(alert => this._alertInResolution.next(alert))
            );
    }

    cancel(alertId: string) {
        return this.httpClient.put(apiUrlsConstants.alerts.cancel(alertId), null)
            .pipe(
                tap(() => this._alertInResolution.next(undefined))
            );
    }

    resolve(alertId: string, resolution: object) {
        return this.httpClient.put(apiUrlsConstants.alerts.resolve(alertId), resolution)
            .pipe(
                tap(() => this._alertInResolution.next(undefined))
            );
    }

    getAlerts(skip: number, limit: number, escalatedOnly = false, types?, field = 'time', order = '-1'): Observable<AlertPageModel> {
        let params = new HttpParams()
            .set('skip', skip)
            .set('limit', limit)
            .set('escalatedOnly', escalatedOnly)
            .set('sortBy', field)
            .set('sortOrder', order);
        if (types) {
            types.forEach(t => {
                params = params.append('types', t);
            });
        }
        return this.httpClient
            .get(apiUrlsConstants.alerts.getAlerts(), { params })
            .pipe(map(AlertPageModel.deserialize));
    }

    getAlertsHistory(params): Observable<object> {
        return this.httpClient.get<object>(apiUrlsConstants.alerts.getAlertsHistory(), { params });
    }

    escalate(alertId: string) {
        return this.httpClient.put(apiUrlsConstants.alerts.escalate(alertId), null);
    }

    updateSettings(newSettings: object): Observable<any> {
        return this.httpClient.put(apiUrlsConstants.alerts.settings(), newSettings);
    }

    private getConfig() {
        return this.httpClient.get(apiUrlsConstants.alerts.settings());
    }

}
