<div class="sae-subheader">
    <div class="container-xl">
        <ul #menu class="subheader-items">
            <li *ngFor="let item of items; trackBy: itemTrackBy;" class="subheader-item">
                <div class="item-content" (click)="toggleShowSlider(item.state)">
                    <div class="item-count">
                        <i *ngIf="item.color" class="circle fa fa-circle" [ngClass]="item.color"></i>
                        <svg-icon *ngIf="!item.color" key="ic_bus" class="inline-flex mr-1" color="#464B51"
                            fontSize="24px">
                        </svg-icon>
                        {{item.label}} {{item.value && "(" + item.value + ")"}}
                    </div>
                    <svg-icon [key]="showSliders.get(item.state) ? 'ic_arrow_down' : 'ic_arrow_right'"
                        class="inline-flex item-dropdown" color="#464B51"
                        fontSize="24px"></svg-icon>
                </div>

                <div class="slider" [ngClass]="{closed: !showSliders.get(item.state)}">
                    <ng-container *ngIf="item.subItems.length === 0">
                        <div class="slider-empty">
                            Não existem autocarros neste estado.
                        </div>
                    </ng-container>

                    <ul class="bus-info-list">
                        <li *ngFor="let subItem of item.subItems; trackBy: subItemTrackBy"
                            [ngClass]="{'cursor-pointer': !!subItem.routeId}" class="bus-info-list-item"
                            (click)="onItemClick(subItem)">
                            <div class="box-container">
                                <div class="boxed"><i class="fas fa-bus"></i>{{subItem.busId}}</div>
                            </div>
                            <ng-container [ngSwitch]="subItem.tripDesc">
                                <div *ngSwitchCase="undefined">
                                    Autocarro em Vazio
                                </div>
                                <div *ngSwitchDefault>
                                    <p><b>Linha:</b> {{subItem.routeId}}</p>
                                    <p *ngIf="subItem.tripDesc"><b>Horário:</b> {{subItem.tripDesc}}</p>
                                </div>
                            </ng-container>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>