import { Component, Input } from '@angular/core';
import { AlertModel, AlertTypeEnum } from 'app/models';

@Component({
    selector: 'sae-alert-type',
    templateUrl: './alert-type.component.html',
    styleUrls: ['./alert-type.component.scss']
})
export class AlertTypeComponent {

    @Input() alert: AlertModel;

    checkDetails(type: AlertTypeEnum): string {
        switch (type) {
            case AlertTypeEnum.SIGNIFICANT_DELAY:
            case AlertTypeEnum.EARLY_DEPARTURE:
            case AlertTypeEnum.ROUTE_DEVIATION:
            case AlertTypeEnum.PROXIMITY:
            case AlertTypeEnum.MISSING_CIRCULATION:
            case AlertTypeEnum.SAME_SCHEDULE:
                return 'line';
            case AlertTypeEnum.EXCESSIVE_DRIVING:
                return 'au';
        }
    }

}
