import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { AlertModel, AlertTag, AlertTypeEnum } from 'app/models';
import { AlertsService } from 'app/services/alerts/alerts.service';
import { loadingObserver } from 'app/utilities';
import { KeycloakService } from 'keycloak-angular';
import { of, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
    selector: 'sae-alert-details',
    templateUrl: './alert-details.component.html',
    styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('map') map!: GoogleMap;
    @ViewChild('stopMarker') stopMarker!: MapMarker;
    @ViewChildren('busMarker') busMarker!: QueryList<MapMarker>;
    @ViewChild('stopInfoWindow') stopInfoWindow!: MapInfoWindow;
    @ViewChildren('busInfoWindow') busInfoWindow!: QueryList<MapInfoWindow>;
    @ViewChild('legend') legend: ElementRef;

    loading = {};

    public alertTypeEnum = AlertTypeEnum;
    public alert: AlertModel;
    public userRoles = [];
    public showViewers = false;
    public tags: AlertTag[] = [];
    private prevState;
    destroyRef = inject(DestroyRef);

    formGroup = new FormGroup({
        operator: new FormControl<string>({ value: undefined, disabled: true }, Validators.required),
        tag: new FormControl<any>('', Validators.required),
        reason: new FormControl<string>('')
    });

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private alertsService: AlertsService,
        private readonly keycloak: KeycloakService,
        private toast: HotToastService
    ) {
        if (router.getCurrentNavigation()) {
            this.prevState = router.getCurrentNavigation().extras.state;
        }

        this.tags = this.alertsService.tags;

        this.keycloak.loadUserProfile().then(userProfile => {
            const userInfoTitle = [userProfile.firstName, userProfile.lastName].join(' ');
            this.formGroup.get('operator').setValue(userInfoTitle);
            this.userRoles = this.keycloak.getUserRoles();
        });
    }

    ngOnInit(): void {
        this.alertsService.alertInResolution
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(alert => {
                // Cancel previous alert
                if (this.alert) this.alertsService.cancel(this.alert.id).pipe(take(1)).subscribe();

                if (alert.viewers) {
                    const viewers = {};
                    alert.viewers.forEach(viewer => {
                        viewers[viewer.user['name']] = viewer;
                    });
                    alert.viewers = Object.values(viewers);
                }
                this.alert = alert;
            });
    }

    ngOnDestroy(): void {
        this.alertsService.cancel(this.alert.id)
            .pipe(
                take(1),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === HttpStatusCode.Forbidden) return of();
                    return throwError(err);
                }))
            .subscribe();
    }

    onEscalateClick() {
        this.alertsService.escalate(this.alert.id)
            .pipe(
                take(1),
                loadingObserver(this.loading, 'escalate')
            )
            .subscribe({
                next: () => {
                    this.toast.success('Alerta escalado', {
                        position: 'top-right',
                        className: 'alerts-sidebar-light-toast'
                    });
                    this.goBack();
                },
                error: () => this.toast.error('Erro a escalar o alerta', {
                    position: 'top-right',
                    className: 'alerts-sidebar-light-toast'
                })
            });
    }

    onCancelClick() {
        this.goBack();
    }

    goBack() {
        this.router.navigate(
            ['../'],
            { state: this.prevState, relativeTo: this.activatedRoute, skipLocationChange: true, queryParamsHandling: 'preserve' }
        );
    }

    onSubmit() {
        if (!this.formGroup.valid) {
            return;
        }
        const resolution = { tags: [this.formGroup.value.tag], reason: this.formGroup.value.reason };
        this.alertsService.resolve(this.alert.id, resolution)
            .pipe(
                take(1),
                loadingObserver(this.loading, 'submit')
            )
            .subscribe({
                next: () => {
                    this.toast.success('Alerta resolvido', {
                        position: 'top-right',
                        className: 'alerts-sidebar-light-toast'
                    });
                    this.router.navigate(
                        ['../'],
                        { state: this.prevState, relativeTo: this.activatedRoute, skipLocationChange: true, queryParamsHandling: 'preserve' }
                    );
                },
                error: () => {
                    this.toast.error('Não foi possível resolver!', {
                        position: 'top-right',
                        className: 'alerts-sidebar-light-toast'
                    });
                }
            });
    }

    toggleShowViewers() {
        this.showViewers = !this.showViewers;
    }
}
