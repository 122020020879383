<aside class="sae-alerts-sidebar" [ngClass]="{'-open': isOpen}">
    <div class="sae-sidebar-backdrop" (click)="close()"></div>

    <div class="alerts-sidebar-nav" #sidebarScrollPane>
        <div class="alerts-sidebar-header">
            <button (click)="close()" class="sae-close-button -light" aria-label="Close">
                <i class="fas fa-chevron-right"></i>
            </button>
            Alertas
        </div>

        <div [@routeAnimations]="outlet && outlet.activatedRouteData['animation']" class="alerts-sidebar-body">
            <router-outlet name="alertsSidebar" #outlet="outlet" (activate)="onRouterActivate()"></router-outlet>
        </div>
    </div>
</aside>