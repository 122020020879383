<div>
    <div class="d-flex items-center">
        <i class="fas fa-triangle-exclamation mr-2"></i>{{alert.type | alertTypeFormat}}
    </div>
    <div *ngIf="checkDetails(alert.type) === 'line'" class="d-flex items-center">
        <svg-icon key="ic_line" class="inline-flex mr-2" color="#464B51" fontSize="24px"></svg-icon>
        <span>{{alert.details['route']['shortName']}} - {{alert.details['route']['longName']}}</span>
    </div>
    <div *ngIf="checkDetails(alert.type) === 'au'" class="d-flex items-center">
        <svg-icon key="ic_agent" class="inline-flex mr-2" color="#464B51" fontSize="24px"></svg-icon>
        <span>{{alert.details['AU']}}</span>
    </div>
</div>