import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { sliderAnimation } from 'app/animations';
import { AlertsService } from 'app/services';

@Component({
    selector: 'sae-alerts-sidebar',
    templateUrl: './alerts-sidebar.component.html',
    styleUrls: ['./alerts-sidebar.component.scss'],
    animations: [sliderAnimation]
})
export class AlertsSidebarComponent {

    @ViewChild('sidebarScrollPane') scrollPaneRef: ElementRef;

    public isOpen = false;

    private htmlElement = document.querySelector('html') as HTMLElement;

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private alertsService: AlertsService
    ) {
    }

    public open() {
        this.isOpen = true;
        this.renderer.setStyle(this.htmlElement, 'overflow-y', 'hidden');
    }

    public close() {
        this.isOpen = false;
        this.alertsService.updateNotViewedCount();
        this.renderer.setStyle(this.htmlElement, 'overflow-y', 'scroll');
        this.router.navigate([{ outlets: { alertsSidebar: null } }], { queryParamsHandling: 'preserve' });
    }

    public onRouterActivate() {
        this.scrollPaneRef.nativeElement.scrollTop = 0;
    }

}
