import { DateTime, Duration } from 'luxon';
import { Hydrator } from '../classes';
import { TripModel } from './trip.model';


export class ArrivalModel {

    busId: string;
    isArrival: boolean;
    routeId: number;
    serviceId: number;
    stopId: number;
    lastPassArrival: DateTime;
    lastPassDeparture: DateTime;
    lastUpdate: DateTime;
    meanArrival: Duration;
    meanDeparture: Duration;
    predictionArrival: Duration;
    predictionDeparture: Duration;
    trip?: TripModel;
    tripId: string;
    delay: number;
    passengers: number;
    status: number;


    static deserialize(jsonObject: object): ArrivalModel {
        if ((jsonObject === undefined || jsonObject === null)) {
            return undefined;
        }

        const mapProperties = {
            lastPassArrival: Hydrator.timestampToDateTime,
            lastPassDeparture: Hydrator.timestampToDateTime,
            lastUpdate: Hydrator.timestampToDateTime,
            meanArrival: Hydrator.secondsToDuration,
            meanDeparture: Hydrator.secondsToDuration,
            predictionArrival: Hydrator.secondsToDuration,
            predictionDeparture: Hydrator.secondsToDuration,
            delay: Hydrator.secondsToDuration
        };

        const keyTransformer = {
            route: 'routeId',
            stop: 'stopId',
            trip: 'tripId',
            tickets: 'passengers'
        };

        const arrival = Object
            .keys(jsonObject)
            .reduce(Hydrator.assign(jsonObject, mapProperties, keyTransformer), new ArrivalModel());

        return arrival;
    }

    static getStoppingTime(arrival: ArrivalModel): Duration {
        if (arrival.lastPassDeparture && arrival.lastPassArrival) {
            return arrival.lastPassDeparture.diff(arrival.lastPassArrival);
        }
    }

    static getPassengers(arrival: ArrivalModel): number {
        if (arrival.lastPassArrival || arrival.passengers !== 0) {
            return arrival.passengers;
        } else {
            return -1;
        }
    }

}
