import { DateTime, Duration } from 'luxon';

export class Hydrator {

    static assign(obj: object, mapProperties: object = {}, keyTransformer: object = {}) {
        return (instance, key) => {
            const transformedKey = keyTransformer[key] !== undefined ? keyTransformer[key] : key;
            const mappedValue = mapProperties[key] !== undefined ? mapProperties[key](obj[key]) : obj[key];

            instance[transformedKey] = mappedValue;
            return instance;
        };
    }

    static fromArray(func: (value: any) => any) {
        return (arr: any[]): any[] => {
            if (arr == undefined) return;
            return arr.map(a => func(a));
        };
    }

    static stringToInt(str: string): number {
        return parseInt(str, 10);
    }

    static stringToFloat(str: string): number {
        return parseFloat(str);
    }

    static toLatLng(obj: object): google.maps.LatLng {
        const lat = obj['latitude'] || obj['lat'];
        const lng = obj['longitude'] || obj['lng'] || obj['lon'];

        return new google.maps.LatLng(lat, lng);
    }

    static arrayToLatLng(arr: number[]): google.maps.LatLng {
        return new google.maps.LatLng(arr[0], arr[1]);
    }

    static secondsToDuration(seconds: number): Duration {
        return Duration.fromObject({ 'seconds': seconds });
    }

    static timestampToDateTime(timestamp: number): DateTime {
        if (timestamp == undefined || timestamp === 0) return;
        return DateTime.fromSeconds(timestamp);
    }

    static secondsSinceMidnToDateTime(seconds: number): DateTime {
        return DateTime.now().startOf('day').plus({ seconds });
    }

    static meterToKm(d: number): number {
        return parseFloat((d / 1000).toFixed(2));
    }

    static toNumberOrString(value: any): (number | string) {
        if (value == undefined) return;
        return isNaN(value) ? value : Number.parseInt(value);
    }
}
